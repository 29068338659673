import { FormControl, Grid, InputLabel,Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import MaterialUiTextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import * as _concat from "lodash/concat";
import * as _differenceWith from "lodash/differenceWith";
import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addMovie, editMovie,getMovieUsers } from "../../actions/movieActions";
import { getUsers } from "../../actions/userActions";
import { getCountries } from "../../actions/countryActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { returnErrors } from "../../actions/errorActions";
import { startLoadingInDialog,finishLoadingInDialog } from "../../actions/loadingActions";
import LoadingOverlay from "react-loading-overlay";


const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    countries: state.country.countries,
    token:state.auth.token,
    isLoadingInDialog: state.loading.isLoadingInDialog
  };
};

function MovieDialogConnect({
  movie,
  users,
  getUsers,
  getCountries,
  countries,
  sessionUser,
  addMovie,
  editMovie,
  openD,
  handleClose,
  token,
  returnErrors,
  startLoadingInDialog,finishLoadingInDialog,
  isLoadingInDialog
  
}) {


  const producers = users.filter((user) => user.type.name == "Producer");

  const [movieProducer, setMovieProducer] = useState({id:''});

  const [movieAdmin, setMovieAdmin] = useState(sessionUser);

  const [movieDistributors, setMovieDistributors] = useState([]);

  const [movieCountry, setMovieCountry] = useState(null);



  useEffect(() => {
    if(openD){
      getUsers();
      getCountries();
    }
  }, [openD]);


  useEffect(()=>{

    if (movie&&movie!="") {
      startLoadingInDialog()
      getMovieUsers(movie.id, token)
        .then((res) => {

          const users = res.data.users;
         
            let newmovieProducer = users.some((user) => user.type.name == "Producer")
                  ? users.filter((user) => user.type.name == "Producer")[0]
                  : producers[0]

            let newmovieAdmin =  users.some((user) => user.type.name == "Admin")
                  ? users.filter((user) => user.type.name == "Admin")[0]
                  : sessionUser
            
            let newmovieDistributors = users.filter((user) => user.type.name == "Distributor")

            let newmovieCountry = res.data.country


            setMovieProducer(newmovieProducer)
            setMovieAdmin(newmovieAdmin)
            setMovieDistributors(newmovieDistributors)
            setMovieCountry(newmovieCountry)

            finishLoadingInDialog()


          // console.log(users)
          // if (users.length > 0) {
          //   setMovieProducer(
          //     users.some((user) => user.type.name == "Producer")
          //       ? users.filter((user) => user.type.name == "Producer")[0]
          //       : producers[0]
          //   );
          //   setMovieAdmin(
          //     users.some((user) => user.type.name == "Admin")
          //       ? users.filter((user) => user.type.name == "Admin")[0]
          //       : sessionUser
          //   );
          //   setMovieDistributors(
          //     users.filter((user) => user.type.name == "Distributor")
          //   );

          //   // const mainMovieVersion =movie.movieVersions.find((mv) => mv.isMainVersion)
          //   // console.log(mainMovieVersion)
          //   // setMovieCountry(countries.find((country) => country.id === mainMovieVersion.countryId))
          //   setMovieCountry(res.data.country)
          // }
        })
        .catch(err=>{console.log(err)
          returnErrors(err.response.data, err.response.status, true)
        });


    }

  },[movie])

  const [selectedCountry, setSelectedCountry] = useState(null);

  const isUserCountryEligible = (user)=>{
    if(!selectedCountry){
      return true;
    }else{

      if(user.countries.length==0){
        return true;
      }else{

        const userCountry = user.countries.find(country=>{
          
          // console.log(country.id);
          // console.log(selectedCountry.id);
          return country.id == selectedCountry.id
        
        });

        // console.log(userCountry);
        return userCountry?true:false;
      }
      
    }

  }

  const [distributors, setDistributors] = useState([]);


  useEffect(() => {
    setSelectedCountry(countries?countries.length>0?countries[0]:null:null)
  }, [countries]);

  useEffect(() => {
    setDistributors(users.filter((user) => (user.type.name == "Distributor" && isUserCountryEligible(user))))
  }, [users]);

  
  useEffect(() => {
    setDistributors(users.filter((user) => (user.type.name == "Distributor" && isUserCountryEligible(user))))
  }, [selectedCountry]);


  // console.log(distributors);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />; 

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    country:Yup.object().required("Required").nullable()

  });

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {

    if (movie) {
      const toBeRemovedValues = _differenceWith(
        movieDistributors,
        values.distributors,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      const toBeAddedValues = _differenceWith(
        values.distributors,
        movieDistributors,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      if (movieProducer.id !== values.producer) {
        toBeAddedValues.push(
          producers.find((prod) => prod.id == values.producer)
        );
        toBeRemovedValues.push(movie.producer);
      }

      const finalValues = {
        ...values,
        ...{ users: toBeAddedValues },
        ...{ removedUsers: toBeRemovedValues },
      };
      delete finalValues.admin;
      delete finalValues.distributors;
      delete finalValues.producer;
      delete finalValues.cinemas;

      // console.log(finalValues);
      finalValues.users = JSON.stringify(finalValues.users);
      finalValues.removedUsers = JSON.stringify(finalValues.removedUsers);

      if(finalValues.country){
        finalValues.country = JSON.stringify(finalValues.country);
      }else{
        delete finalValues.country;
      }
      


      if(values.dkdm){

        // console.log(values.dkdm[0]);

        const toBeAddedValues = _differenceWith(
          values.dkdm,
          movie.movieVersions,
          (arrValue, othValue) => arrValue.name == othValue.name
        );

        // console.log(toBeAddedValues);

        finalValues.dkdm = toBeAddedValues;

        const defUsers = values.distributors;
        defUsers.push(values.admin)
        defUsers.push(producers.find((prod) => prod.id == values.producer))

        finalValues.commonValues = JSON.stringify({
          users: defUsers
      })
    }

      editMovie(finalValues);
    } else {
      const finalValues = {
        ...values,
        ...{
          users: _concat(values.distributors, [
            values.admin,
            producers.find((prod) => prod.id == values.producer),
          ]),
        },
      };
      delete finalValues.admin;
      delete finalValues.distributors;
      delete finalValues.producer;
      finalValues.users = JSON.stringify(finalValues.users);
      if(finalValues.country){
        finalValues.country = JSON.stringify(finalValues.country);
      }else{
        delete finalValues.country;
      }

      addMovie(finalValues);
    }

    setSubmitting(false);

    closeDialog();
  };

  const handleFileUploadClick = (e, setFieldValue) => {
    const files = e.currentTarget.files;

    setFieldValue("dkdm", files);

    let dkdmNames = "";


    for (const file of files) {
      dkdmNames+=","+file.name;
    }

    setFieldValue("dkdmName", dkdmNameCombined+dkdmNames);
  };



  const dkdmNameCombined = movie?movie.movieVersions.map(mv=>mv.dkdm.split("\\").pop()).join(','):"";

  let initialValues = movie
    ? {
        ...movie,
        ...{ producer: movieProducer?movieProducer.id:producers[0]},
        ...{ admin: movieAdmin },
        ...{ distributors: movieDistributors },
        ...{ country: movieCountry },
        ...{ dkdmName:dkdmNameCombined},
      }
    : {
        name: "",
        localizedName: "",
        producer: producers.length > 0 ? producers[0].id : "",
        admin: sessionUser,
        distributors: [],
        enabled: true,
        dkdm: "",
        dkdmName: "Choose DKDM",
        // country:{id:"All",name:"All"}
        country:countries?countries.length>0?countries[0]:null:null
      };



  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <LoadingOverlay
          active={isLoadingInDialog}
          spinner
          text="Loading your content..."
        >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="end"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
            enableReinitialize
          >
            {({ isSubmitting, values, setFieldValue,touched,errors,handleBlur }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name"
                      required
                      type="text"
                      name="name"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Localized Name"
                      type="text"
                      name="localizedName"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl style={{ minWidth: "100%" }}>
                      <InputLabel htmlFor="producer">Producer</InputLabel>
                      <Field
                        component={Select}
                        name="producer"
                        inputProps={{
                          id: "producer",
                        }}
                      >
                        {producers.map((producer) => (
                          <MenuItem key={producer.id} value={producer.id}>
                            {producer.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  </Grid>



                  <hr />

                <br />
                <h5>Main Version</h5>

                  <Grid container spacing={3}>


                  <Grid item xs={3}>
                  <Autocomplete
                    // multiple
                    // filterSelectedOptions
                    disableCloseOnSelect
                    name={`country`}
                    options={countries}
                    value={values.country}
                    getOptionSelected={(option, value) => {
                      return option.id == value.id;
                    }}
                    onClose={handleBlur}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`country`, value);
                      setFieldValue(`distributors`, []);
                      setSelectedCountry(value);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                        color="primary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <MaterialUiTextField
                        label={`Restrict Main Version to Country`}
                        fullWidth
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        name={`country`}
                        {...params}
                      />
                    )}
                  />
                </Grid>

               

                <Grid item xs={3}>
                  <Autocomplete
                    multiple
                    // filterSelectedOptions
                    disableCloseOnSelect
                    name={`distributors`}
                    options={distributors}
                    value={values.distributors}
                    getOptionSelected={(option, value) => {
                      return option.id == value.id;
                    }}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`distributors`, value);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                        color="primary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <MaterialUiTextField
                        label={`Assign Distributors`}
                        fullWidth
                        name={`distributors`}
                        {...params}
                      />
                    )}
                  />
                </Grid>

                </Grid>

                <br />

                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item>
                    <Field
                      InputProps={{
                        readOnly: true,
                      }}
                      component={TextField}
                      label={"DKDM"}
                      type="text"
                      name="dkdmName"
                    />
                  </Grid>

                  <Grid item>
                    <input
                      accept=".xml"
                      id="contained-button-file"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        handleFileUploadClick(e, setFieldValue);
                      }}
                      hidden
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginBottom: 0 }}
                    >
                      {/* <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <CloudUploadIcon />
                      </IconButton> */}
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                <br />
                <Button color="primary" type="submit">
                  Save
                </Button>

                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}

const MovieDialog = connect(mapStateToProps, {
  addMovie,
  editMovie,
  getUsers,
  getCountries,
  returnErrors,
  startLoadingInDialog,finishLoadingInDialog,
})(MovieDialogConnect);

export default MovieDialog;
