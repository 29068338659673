import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addCinema, editCinema } from "../../actions/cinemaActions";
import { getCountries } from "../../actions/countryActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import * as _differenceWith from "lodash/differenceWith";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return { countries: state.country.countries };
};

function CinemaDialogConnect({
  cinema,
  countries,
  getCountries,
  addCinema,
  editCinema,
  openD,
  handleClose,
}) {
  useEffect(() => {
    if(openD){
    getCountries();
    }
  }, [openD]);

  const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),

    KdmEmail: Yup.string().email("Invalid email"),

    contactEmail: Yup.string().email("Invalid email"),
    contactPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {

    if (cinema) {

      const finalValues = {
        ...values
      };

      if(values.certificates){

        // console.log(values.dkdm[0]);

        const toBeAddedValues = _differenceWith(
          values.certificates,
          cinema.screens,
          (arrValue, othValue) => arrValue.name == othValue.name
        );

        // console.log(toBeAddedValues);

        finalValues.certificates = toBeAddedValues;
    }
      editCinema(finalValues);
    } else {
      addCinema(values);
    }

    setSubmitting(false);
    closeDialog();
  };

  const handleFileUploadClick = (e, setFieldValue) => {
    const files = e.currentTarget.files;

    setFieldValue("certificates", files);

    let certificatesNames = "";


    for (const file of files) {
      certificatesNames+=","+file.name;
    }

    setFieldValue("certificatesNames", certificatesNamesCombined+certificatesNames);
  };

  const certificatesNamesCombined = cinema?cinema.screens.map(screen=>screen.serverCertificate.split("\\").pop()).join(','):"";
  const initialValues = cinema
    ? {...cinema,
      ...{ certificatesNames:certificatesNamesCombined},}
    : {
        name: "",
        name2: "",
        KdmEmail: "",
        countryId: countries.length > 0 ? countries[0].id : "",
        address: "",
        contactName: "",
        enabled: true,
        contactPhone: "",
        contactEmail: "",
        zipcode: "",
        certificates:"",
        certificatesNames:"Choose Certificates"
      };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name"
                      required
                      type="text"
                      name="name"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name2"
                      type="text"
                      name="name2"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="KDM Email"
                      type="email"
                      name="KdmEmail"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl style={{ minWidth: "100%" }}>
                      <InputLabel htmlFor="countryId">Country</InputLabel>
                      <Field
                        component={Select}
                        name="countryId"
                        inputProps={{
                          id: "countryId",
                        }}
                      >
                        {countries.map((country, index) => (
                          <MenuItem key={index} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Address"
                      type="text"
                      name="address"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Contact Name"
                      type="text"
                      name="contactName"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Contact Phone"
                      type="tel"
                      name="contactPhone"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Contact Email"
                      type="email"
                      name="contactEmail"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Zipcode"
                      type="number"
                      name="zipcode"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  {/* <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="flex-end"
                  >
                     <Field
                      component={CheckboxWithLabel}
                      name="enabled"
                      color="primary"
                      type="checkbox"
                      Label={{ label: "Enabled" }}
                    />
                  </Grid> */}
                </Grid>

                <br />

                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item>
                    <Field
                      InputProps={{
                        readOnly: true,
                      }}
                      component={TextField}
                      label={cinema?"Add New Screens":"Screens"}
                      type="text"
                      name="certificatesNames"
                    />
                  </Grid>

                  <Grid item>
                    <input
                      accept=".pem,.crt"
                      id="contained-button-file"
                      type="file"
                      multiple
                      onChange={(e) => {
                        handleFileUploadClick(e, setFieldValue);
                      }}
                      hidden
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginBottom: 0 }}
                    >
                      {/* <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <CloudUploadIcon />
                      </IconButton> */}
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                <br />
                <Button color="primary" type="submit">
                  Save
                </Button>

                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CinemaDialog = connect(mapStateToProps, {
  addCinema,
  editCinema,
  getCountries,
})(CinemaDialogConnect);

export default CinemaDialog;
