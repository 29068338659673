import { combineReducers } from 'redux'
import userReducer from './user'
import authReducer from './auth'
import errorReducer from './error'
import countryReducer from './country'
import cinemaReducer from './cinema'
import screenReducer from './screen'
import movieReducer from './movie'
import loadingReducer from './loading'
import reportReducer from './report'
import {reducer as notifications} from 'react-notification-system-redux';


export default combineReducers({
  user:userReducer, auth:authReducer, error:errorReducer, notifications:notifications, country:countryReducer,
  cinema:cinemaReducer, screen:screenReducer, movie:movieReducer, loading:loadingReducer, report:reportReducer
})