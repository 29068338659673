import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import MaterialUiTextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as _forEach from "lodash/forEach";
import * as _union from "lodash/union";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addUser, editUser } from "../../actions/userActions";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return {};
};

function AdminDialogConnect({ user, addUser, editUser, openD, handleClose }) {
  const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password: user
      ? Yup.string().min(2, "Too Short!").max(50, "Too Long!")
      : Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),
    name: Yup.string().required("Required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });

  const [showPassword, setShowPassword] = useState(false);

  const closeDialog = () => {
    handleClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const permToTextMap = {
    "AssignToDistributor":"Assign Movie To Distributor",
    "LoadDkdms":"Upload DKDMs"
  }

  const textToPermMap = {
    "Assign Movie To Distributor":"AssignToDistributor",
    "Upload DKDMs":"LoadDkdms"
  }

  const handleOnSubmit = (values, { setSubmitting }) => {
    const finalValues = {
      ...values,
      ...{
        permissions: convertValueArrayToPermissionsObjects(values.permissions),
      },
    };

    if (user) {
      if (finalValues.password == "") {
        delete finalValues.password;
      }

      editUser(finalValues);
    } else {
      addUser(finalValues);
    }

    setSubmitting(false);
    closeDialog();
  };

  const userPermissions = {
    distributors_Add: false,
    distributors_Edit: false,
    distributors_Delete: false,
    // distributors_EnableOrDisable: false,

    producers_Add: false,
    producers_Edit: false,
    producers_Delete: false,
    // producers_EnableOrDisable: false,

    countries_Add: false,
    countries_Edit: false,
    countries_Delete: false,
    // countries_EnableOrDisable: false,

    cinemas_Add: false,
    cinemas_Edit: false,
    cinemas_Delete: false,
    // cinemas_EnableOrDisable: false,

    screens_Add: false,
    screens_Edit: false,
    screens_Delete: false,
    // screens_EnableOrDisable: false,

    movies_Add: false,
    movies_Edit: false,
    movies_Delete: false,
    // movies_EnableOrDisable: false,
    movies_AssignToDistributor: false,
    movies_LoadDkdms: false,
  };

  const handleSelectAll = (permissions, isChecked, setFieldValue) => {
    if (isChecked) {
      permissions.forEach((perm) => {
        perm.values = _union(perm.values, perm.options);
      });
    } else {
      permissions.forEach((perm) => {
        perm.values = [];
      });
    }

    setFieldValue("values.permissions", permissions);
  };

  const convertPermissionsObjectToValueArray = (permissions) => {
    const transformedPermissions = [
      {
        type: "distributors",
        options: ["Add", "Edit", "Delete"],
        values: [],
      },
      {
        type: "producers",
        options: ["Add", "Edit", "Delete"],
        values: [],
      },
      {
        type: "countries",
        options: ["Add", "Edit", "Delete"],
        values: [],
      },
      {
        type: "cinemas",
        options: ["Add", "Edit", "Delete"],
        values: [],
      },
      {
        type: "screens",
        options: ["Add", "Edit", "Delete"],
        values: [],
      },
      {
        type: "movies",
        options: ["Add", "Edit", "Delete", "Assign Movie To Distributor", "Upload DKDMs"],
        values: [],
      },
    ];

    _forEach(permissions, function (value, key) {
      const splitted = key.split("_");

      const type = splitted[0];

      if (value) {
        transformedPermissions.forEach((perm) => {
          if (perm.type == type) {
            perm.values.push(permToTextMap[splitted[1]]?permToTextMap[splitted[1]]:splitted[1]);
          }
        });
      }
    });

    return transformedPermissions;
  };

  const convertValueArrayToPermissionsObjects = (transformedPermissions) => {
    const userPermissions = {
      distributors_Add: false,
      distributors_Edit: false,
      distributors_Delete: false,
      // distributors_EnableOrDisable: false,

      producers_Add: false,
      producers_Edit: false,
      producers_Delete: false,
      // producers_EnableOrDisable: false,

      countries_Add: false,
      countries_Edit: false,
      countries_Delete: false,
      // countries_EnableOrDisable: false,

      cinemas_Add: false,
      cinemas_Edit: false,
      cinemas_Delete: false,
      // cinemas_EnableOrDisable: false,

      screens_Add: false,
      screens_Edit: false,
      screens_Delete: false,
      // screens_EnableOrDisable: false,

      movies_Add: false,
      movies_Edit: false,
      movies_Delete: false,
      // movies_EnableOrDisable: false,
      movies_AssignToDistributor: false,
      movies_LoadDkdms: false,
    };

    transformedPermissions.forEach((tperm) => {
      tperm.values.forEach((value) => {
        userPermissions[`${tperm.type}_${textToPermMap[value]?textToPermMap[value]:value}`] = true;
      });
    });

    return userPermissions;
  };

  const initialValues = user
    ? {
        ...user,
        ...{
          permissions: convertPermissionsObjectToValueArray(user.permissions),
        },
        ...{ password: "" },
      }
    : {
        username: "",
        password: "",
        email: "",
        name: "",
        phone: "",
        enabled: true,
        type: "Admin",
        permissions: convertPermissionsObjectToValueArray(userPermissions),
      };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label="Username"
                      type="text"
                      name="username"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required={user ? false : true}
                      label={user ? "Reset Password" : "Password"}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword && <Visibility />}
                              {!showPassword && <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <ErrorMessage name="password" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label="Email"
                      type="email"
                      name="email"
                    />
                    {/* <ErrorMessage name="email" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name"
                      type="text"
                      name="name"
                      required
                    />
                    {/* <ErrorMessage name="name" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Phone"
                      type="tel"
                      name="phone"
                    />
                    {/* <ErrorMessage name="phone" component="div" /> */}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="flex-end"
                  >
                    {/* <Field
                      component={CheckboxWithLabel}
                      name="enabled"
                      color="primary"
                      type="checkbox"
                      Label={{ label: "Enabled" }}
                    /> */}
                    {/* <ErrorMessage name="enabled" component="div" /> */}
                  </Grid>
                </Grid>
                <hr />

                <br />
                <h5>Permissions</h5>
                {/* <br /> */}

                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="selectAll"
                        onChange={(event) => {
                          handleSelectAll(
                            values.permissions,
                            event.target.checked,
                            setFieldValue
                          );
                        }}
                      />
                    }
                    label="Select All"
                    labelPlacement="end"
                  />
                  {/* <Field component={CheckboxWithLabel} name="selectAll" type="checkbox" Label={{ label: 'Select All Permissions' }} 
                    onChange={(event)=>{

                      console.log('here')
                      handleSelectAll(values.permissions)
                      if(event.target.checked){

                      }else{

                      }

                      



                    }} /> */}
                  {/* <ErrorMessage name="enabled" component="div" /> */}
                </Grid>

                <FieldArray
                  name="permissions"
                  render={(arrayHelpers) => (
                    <Grid container spacing={3}>
                      {values.permissions.map((perm, index) => (
                        <Grid key={index} item xs={2}>
                          {/* {console.log(perm)} */}
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            limitTags={3}
                            name={`permissions.${index}.values`}
                            options={perm.options}
                            value={values.permissions[index].values}
                            getOptionSelected={(option, value) => {
                              return option == value;
                            }}
                            onChange={(e, value) => {
                              setFieldValue(
                                `permissions.${index}.values`,
                                value
                              );
                            }}
                            renderInput={(params) => (
                              <MaterialUiTextField
                                label={`${perm.type} Permissions`}
                                fullWidth
                                name={`permissions.${index}.values`}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                />
                {/* </Grid> */}

                <br />

                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const AdminDialog = connect(mapStateToProps, { addUser, editUser })(
  AdminDialogConnect
);

export default AdminDialog;
