import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import MaterialUiTextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addCountry, editCountry } from "../../actions/countryActions";
import countries from "./countries.json";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return {};
};

function CountryDialogConnect({
  country,
  addCountry,
  editCountry,
  openD,
  handleClose,
}) {
  const schema = Yup.object().shape({});

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (country) {
      editCountry(values);
    } else {
      addCountry(values);
    }

    setSubmitting(false);
    closeDialog();
  };

  const initialValues = country
    ? {
        ...country,
        ...{ country: countries.find((con) => con.name == country.name) },
      }
    : {
        country: countries[0],
        name2: "",
        name: "",
        code: "",
        timezone: countries[0].timezones[0],
        enabled: true,
      };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleClose}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    {/* {console.log(perm)} */}
                    <Autocomplete
                      disableClearable
                      name={`country`}
                      disabled={country ? true : false}
                      options={countries}
                      value={values.country}
                      getOptionSelected={(option, value) => {
                        return option.name == value.name;
                      }}
                      getOptionLabel={(option) =>
                        `${option.name} - ${option.country_code}`
                      }
                      onChange={(e, value) => {
                        setFieldValue(`country`, value);

                        setFieldValue(`name`, value.name);

                        setFieldValue(`code`, value.country_code);

                        setFieldValue(
                          `timezone`,
                          value ? value.timezones[0] : ""
                        );
                      }}
                      renderInput={(params) => (
                        <MaterialUiTextField
                          label={`Assign Distributors`}
                          fullWidth
                          name={`distributors`}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl style={{ minWidth: "100%" }}>
                      <InputLabel htmlFor="timezone">Timezone</InputLabel>
                      <Field
                        component={Select}
                        name="timezone"
                        inputProps={{
                          id: "timezone",
                        }}
                      >
                        {values.country.timezones.map((timezone, index) => (
                          <MenuItem key={index} value={timezone}>
                            {timezone}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name2"
                      type="text"
                      name="name2"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="flex-end"
                  >
                    {/* <Field
                      component={CheckboxWithLabel}
                      name="enabled"
                      color="primary"
                      type="checkbox"
                      Label={{ label: "Enabled" }}
                    /> */}
                    {/* <ErrorMessage name="enabled" component="div" /> */}
                  </Grid>
                </Grid>

                <br />
                <Button color="primary" type="submit">
                  Save
                </Button>

                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CountryDialog = connect(mapStateToProps, { addCountry, editCountry })(
  CountryDialogConnect
);

export default CountryDialog;
