import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import Loading from '../components/Loading'

const locationHelper = locationHelperBuilder({})

export const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.auth.isAuthenticated,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',

  authenticatingSelector: state => state.auth.isLoading,
  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: Loading
})

export const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/home',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state => !state.auth.isAuthenticated && !state.auth.isLoading,

  // authenticatedSelector: state => state.auth.user === null && state.auth.isLoading === false ,
  // authenticatedSelector:state => state.auth.user === null && state.user.isLoading === false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',

  // // Returns true if the user auth state is loading
  // authenticatingSelector: state => state.auth.isLoading,
  // // Render this component when the authenticatingSelector returns true
  // AuthenticatingComponent: Loading
})

export const userIsSystemAdminRedir = connectedRouterRedirect({
  redirectPath: '/home',
  allowRedirectBack: false,
  authenticatedSelector:state => state.auth.user !== null && state.auth.user.type.name == "SystemAdmin",
  predicate: user => user.type.name == "SystemAdmin",
  wrapperDisplayName: 'userIsSystemAdminRedir'
})

export const visableComponentToSystemAdmin = connectedAuthWrapper({
  authenticatedSelector: state => state.auth.user !== null && state.auth.user.type.name == "SystemAdmin",
  wrapperDisplayName: 'visableComponentToSystemAdmin'
})


export const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: '/home',
  allowRedirectBack: false,
  authenticatedSelector:state => state.auth.user !== null && state.auth.user.type.name == "Admin",
  predicate: user => user.type.name == "Admin",
  wrapperDisplayName: 'userIsAdminRedir'
})

export const visableComponentToAdmin = connectedAuthWrapper({
  authenticatedSelector: state => state.auth.user !== null && state.auth.user.type.name == "Admin",
  wrapperDisplayName: 'visableComponentToAdmin'
})


export const userIsAdminOrDistributorRedir = connectedRouterRedirect({
  redirectPath: '/home',
  allowRedirectBack: false,
  authenticatedSelector:state => state.auth.user !== null && (state.auth.user.type.name == "Admin"||state.auth.user.type.name == "Distributor"),
  predicate: user => user.type.name == "Admin"||user.type.name == "Distributor",
  wrapperDisplayName: 'userIsAdminOrDistributorRedir'
})

export const visableComponentToAdminAndDistributor = connectedAuthWrapper({
  authenticatedSelector: state => state.auth.user !== null && (state.auth.user.type.name == "Admin"||state.auth.user.type.name == "Distributor"),
  wrapperDisplayName: 'visableComponentToAdminAndDistributor'
})




export const userIsDistributorRedir = connectedRouterRedirect({
  redirectPath: '/home',
  allowRedirectBack: false,
  authenticatedSelector:state => state.auth.user !== null && state.auth.user.type.name == "Distributor",
  predicate: user => user.type.name == "Distributor",
  wrapperDisplayName: 'userIsDistributorRedir'
})

export const visableComponentToDistributor = connectedAuthWrapper({
  authenticatedSelector: state => state.auth.user !== null && state.auth.user.type.name == "Distributor",
  wrapperDisplayName: 'visableComponentToDistributor'
})

// export const visableComponentByPermission = (permission) => connectedAuthWrapper({
//   authenticatedSelector: state => state.auth.user !== null && state.auth.user.permissions[permission],
//   wrapperDisplayName: 'visableComponentByPermission'
// })