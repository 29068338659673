import DateFnsAdapter from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadUser } from "../actions/authActions";
import { markUserNotificationAsRead } from "../actions/userActions";
import { compareDesc } from "date-fns";

const dateFns = new DateFnsAdapter();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    marginLeft: "auto",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

function NotificationConnect({ loadUser, user, markUserNotificationAsRead }) {
  const classes = useStyles();
  const theme = useTheme();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(user.notifications);
  }, [user]);

  const handleMarkNotificationAsRead = (notification) => {
    markUserNotificationAsRead(user, notification);
  };

  const handleMarkAllAsReadClick = () => {
    notifications.forEach((notification) => {
      if (!notification.isRead) {
        markUserNotificationAsRead(user, notification);
      }
    });
  };

  notifications.sort((a, b) =>
    compareDesc(dateFns.date(a.createdAt), dateFns.date(b.createdAt))
  );

  const NotificationsList = notifications.map((notification) => (
    <React.Fragment key={notification.id}>
      <ListItem
        alignItems="flex-start"
        role={undefined}
        dense
        divider
        style={{ backgroundColor: notification.isRead ? "#FFFFFF" : "#bbdefb" }}
      >
        <ListItemText
          id={`checkbox-list-label-${notification.id}`}
          primary={notification.message}
          secondary={dateFns.format(
            dateFns.date(notification.createdAt),
            "MM/dd/yyyy HH:mm:ss"
          )}
        />
        <ListItemSecondaryAction hidden={notification.isRead}>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => {
              handleMarkNotificationAsRead(notification);
            }}
          >
            <CheckIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {/* <Divider/> */}
    </React.Fragment>
  ));

  return (
    <div>
      <h3>Notifications</h3>
      <Button
        type="button"
        variant="contained"
        onClick={handleMarkAllAsReadClick}
        color="primary"
      >
        Mark All As Read
      </Button>

      <List>{NotificationsList}</List>
    </div>
  );
}

const Notification = connect(mapStateToProps, {
  loadUser,
  markUserNotificationAsRead,
})(NotificationConnect);

export default Notification;
