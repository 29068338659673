import Add from "@material-ui/icons/Add";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CancelIcon from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteMovie,
  editMovie,
  getMovies,
  requestMovie,
} from "../../actions/movieActions";
import MovieDialog from "./MovieDialog";
import MovieVersions from "./MovieVersions";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return { movies: state.movie.movies, sessionUser: state.auth.user };
};

function MovieConnect({
  movies,
  sessionUser,
  getMovies,
  deleteMovie,
  editMovie,
  requestMovie,
}) {
  useEffect(() => {
    getMovies();
  }, [getMovies]);

  const [openDialog, setOpenDialog] = useState(false);

  const [selectedMovie, setSelectedMovie] = useState("");

  const [selectedRowsNum, setSelectedRowsNum] = useState(0);

  const onClickEdit = (rowData) => {
    setSelectedMovie(rowData[0]);
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedMovie("");
  };

  const onClickDelete = (rowData) => {
    rowData.forEach((user) => {
      deleteMovie(user);
    });
  };

  const onClickEnable = (rowData) => {
    rowData.forEach((user) => {
      const updatedMovie = { ...{ id: user.id }, ...{ enabled: true } };
      editMovie(updatedMovie);
    });
  };

  const onClickDisable = (rowData) => {
    rowData.forEach((user) => {
      const updatedMovie = { ...{ id: user.id }, ...{ enabled: false } };
      editMovie(updatedMovie);
    });
  };

  const onClickRequestMovie = (rowData) => {
    requestMovie(rowData[0], sessionUser);
  };

  const dynamicLookupObject = { true: "Enabled", false: "Disabled" };
 
  return (
    <div>
      <MovieDialog
        openD={openDialog}
        handleClose={handleClose}
        movie={selectedMovie}
        sessionUser={sessionUser}
      />
      <MaterialTable
        icons={tableIcons}
        options={{
          selection: true,
          headerStyle: {
            backgroundColor: "#005374",
            color: "#FFF",
          },
        }}
        columns={[
          { title: "Name", field: "name" },
          { title: "Localized Name", field: "localizedName" },
          { title: "Version", field: "version" },
          {
            title: "Status",
            field: "enabled",
            lookup: dynamicLookupObject,
            hidden: true,
          },

          { title: "createdAt", field: "createdAt", type: "datetime" },
          { title: "updatedAt", field: "updatedAt", type: "datetime" },
        ]}
        data={movies}
        title={"Movies"}
        actions={[
          {
            icon: Edit,
            tooltip: "Edit Movie",
            isFreeAction: false,
            onClick: (event, rowData) => onClickEdit(rowData),
            disabled:
              selectedRowsNum != 1 || !sessionUser.permissions.movies_Edit,
          },
          {
            icon: Delete,
            tooltip: "Delete Movie",
            onClick: (event, rowData) => onClickDelete(rowData),
            disabled: !sessionUser.permissions.movies_Delete,
          },
          {
            icon: CheckCircleIcon,
            tooltip: "Enable Movie",
            onClick: (event, rowData) => onClickEnable(rowData),
            disabled: !sessionUser.permissions.movies_EnableOrDisable,
            hidden: true,
          },
          {
            icon: CancelIcon,
            tooltip: "Disable Movie",
            onClick: (event, rowData) => onClickDisable(rowData),
            disabled: !sessionUser.permissions.movies_EnableOrDisable,
            hidden: true,
          },
          {
            icon: Add,
            tooltip: "Add Movie",
            isFreeAction: true,
            onClick: (event) => handleClickOpen(),
            disabled: !sessionUser.permissions.movies_Add,
          },
          {
            icon: Add,
            tooltip: "Request Movie",
            onClick: (event, rowData) => onClickRequestMovie(rowData),
            hidden: sessionUser.type.name != "Distributor",
            disabled:
              selectedRowsNum != 1 || !sessionUser.permissions.movies_Request,
          },
        ]}
        onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
        detailPanel={(rowData) => {
          return (
            <MovieVersions
              movie={rowData}
            />
          );
        }}
      />
    </div>
  );
}

const Movie = connect(mapStateToProps, {
  getMovies,
  deleteMovie,
  editMovie,
  requestMovie,
})(MovieConnect);

export default Movie;
