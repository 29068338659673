import React from "react";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  userIsAuthenticated,
  userIsNotAuthenticated,
} from "../helper/authenticator";
import Login from "./Authentication/Login";
import MainPage from "./MainPage";

const mapStateToProps = (state) => {
  return { notifications: state.notifications };
};

const LoginComp = userIsNotAuthenticated(Login);
const ProtectedComp = userIsAuthenticated(MainPage);

function MainConnect({ notifications }) {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={LoginComp} />
          <Route path="/login" component={LoginComp} />
          <Route path="/home" component={ProtectedComp} />
        </Switch>
        <Notifications notifications={notifications} />
      </div>
    </Router>
  );
}

const Main = connect(mapStateToProps, {})(MainConnect);
export default Main;
