import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { changePassword } from "../../actions/userActions";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

function ProfileDialogConnect({ user, changePassword, openD, handleClose }) {
  const schema = Yup.object().shape({
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    newPassword: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    confirmedPassword: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    values.username = user.username;

    changePassword(values);
    setSubmitting(false);
    closeDialog();
  };

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    password: "",
    newPassword: "",
    confirmedPassword: "",
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label={"Password"}
                      type={showPassword1 ? "text" : "password"}
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword1}
                            >
                              {showPassword1 && <Visibility />}
                              {!showPassword1 && <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label={"New Password"}
                      type={showPassword2 ? "text" : "password"}
                      name="newPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword2}
                            >
                              {showPassword2 && <Visibility />}
                              {!showPassword2 && <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label={"Confirm Password"}
                      type={showPassword3 ? "text" : "password"}
                      name="confirmedPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword3}
                              onMouseDown={handleMouseDownPassword3}
                            >
                              {showPassword3 && <Visibility />}
                              {!showPassword3 && <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <br />

                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ProfileDialog = connect(mapStateToProps, { changePassword })(
  ProfileDialogConnect
);

export default ProfileDialog;
