import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import MovieIcon from "@material-ui/icons/Movie";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import TheatersIcon from "@material-ui/icons/Theaters";
import VideocamIcon from "@material-ui/icons/Videocam";
import AssignmentIcon from "@material-ui/icons/Assignment";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import io from "socket.io-client";
import { loadUser, logout } from "../actions/authActions";
import { getMovies } from "../actions/movieActions";

import { markUserNotificationAsRead } from "../actions/userActions";
import {
  userIsAdminOrDistributorRedir,
  userIsAdminRedir,
  userIsAuthenticated,
  userIsDistributorRedir,
  userIsSystemAdminRedir,
  visableComponentToAdmin,
  visableComponentToDistributor,
  visableComponentToSystemAdmin,
} from "../helper/authenticator";
import { sendNotification } from "../helper/notifier";
import Admin from "./Admin/Admin";
import Cinema from "./Cinema/Cinema";
import Country from "./Country/Country";
import Distributor from "./Distributor/Distributor";
import Home from "./Home";
import Movie from "./Movie/Movie";
import MoviesAssignedToDistributor from "./Movie/MoviesAssignedToDistributor";
import Notification from "./Notification";
import Producer from "./Producer/Producer";
import Profile from "./Profile/Profile";
import Screen from "./Screen/Screen";
import DistributorsActions from "./Reports/DistributorsActions";
import HistoryIcon from "@material-ui/icons/History";

import History from "./History/History";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#005374",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    marginLeft: "auto",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => {
  return { user: state.auth.user, loading: state.loading };
};

const AdminsAuth = userIsAuthenticated(userIsSystemAdminRedir(Admin));
const ProducerAuth = userIsAuthenticated(userIsAdminRedir(Producer));
const DistributorAuth = userIsAuthenticated(userIsAdminRedir(Distributor));
const CountryAuth = userIsAuthenticated(userIsAdminRedir(Country));
const CinemaAuth = userIsAuthenticated(userIsAdminRedir(Cinema));
const ScreenAuth = userIsAuthenticated(userIsAdminRedir(Screen));
const DistributorsActionsAuth = userIsAuthenticated(
  userIsAdminRedir(DistributorsActions)
);
const MovieAuth = userIsAuthenticated(userIsAdminRedir(Movie));
const MoviesAssignedToDistributorAuth = userIsAuthenticated(
  userIsDistributorRedir(MoviesAssignedToDistributor)
);

const HistoryAuth = userIsAuthenticated(userIsDistributorRedir(History));

let socket;

function MainPageConnect({
  logout,
  loadUser,
  user,
  markUserNotificationAsRead,
  loading,
  getMovies,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [notifications, setNotifications] = useState([]);

  const ENDPOINT = process.env.REACT_APP_SOCKET_URL
    ? process.env.REACT_APP_SOCKET_URL
    : "/";

  useEffect(() => {
    setNotifications(user.notifications);
  }, [user]);

  useEffect(() => {
    socket = io(ENDPOINT);

    socket.emit("join", { user });
  }, [ENDPOINT, user]);

  useEffect(() => {
    socket.on("notification", (notificationObj) => {
      // console.log(notificationObj);
      if (notificationObj.type == "new") {
        sendNotification(
          notificationObj.notification.message,
          notificationObj.notification.type
            ? notificationObj.notification.type
            : "info"
        );
      }
      loadUser();
    });

    socket.on("refresh", (model) => {
      switch (model) {
        case "MOVIE":
          getMovies();
          break;

        default:
          break;
      }
    });
  }, []);

  let { path, url } = useRouteMatch();

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const SystemAdminLinks = () => {
    return (
      <>
        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/admins`}
        >
          <ListItemIcon>{<SupervisedUserCircleIcon />}</ListItemIcon>
          <ListItemText primary="Admins" />
        </ListItem>
        <Divider />
      </>
    );
  };

  const AdminLinks = () => {
    return (
      <>
        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/distributors`}
        >
          <ListItemIcon>{<PersonIcon />}</ListItemIcon>
          <ListItemText primary="Distributors" />
        </ListItem>

        <Divider />

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/countries`}
        >
          <ListItemIcon>{<LanguageIcon />}</ListItemIcon>
          <ListItemText primary="Countries" />
        </ListItem>

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/cinemas`}
        >
          <ListItemIcon>{<TheatersIcon />}</ListItemIcon>
          <ListItemText primary="Cinemas" />
        </ListItem>

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/screens`}
        >
          <ListItemIcon>{<VideocamIcon />}</ListItemIcon>
          <ListItemText primary="Screens" />
        </ListItem>

        <Divider />

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/producers`}
        >
          <ListItemIcon>{<PersonOutlineIcon />}</ListItemIcon>
          <ListItemText primary="Producers" />
        </ListItem>

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/movies`}
        >
          <ListItemIcon>{<MovieIcon />}</ListItemIcon>
          <ListItemText primary="Movies" />
        </ListItem>

        <Divider />

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/reports/distributors-actions`}
        >
          <ListItemIcon>{<AssignmentIcon />}</ListItemIcon>
          <ListItemText primary="Distributors Actions Report" />
        </ListItem>
        <Divider />
      </>
    );
  };

  const DistributorLinks = () => {
    return (
      <>
        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/distribute-movies`}
        >
          <ListItemIcon>{<MovieIcon />}</ListItemIcon>
          <ListItemText primary="Distribute Movies" />
        </ListItem>

        <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/history`}
        >
          <ListItemIcon>{<HistoryIcon />}</ListItemIcon>
          <ListItemText primary="History" />
        </ListItem>
        {/* <ListItem
          button
          onClick={handleDrawerClose}
          component={Link}
          to={`${url}/movies`}
        >
          <ListItemIcon>{<MailIcon />}</ListItemIcon>
          <ListItemText primary="Request Movies" />
        </ListItem> */}
        <Divider />
      </>
    );
  };

  const SystemAdminLinksComp = userIsAuthenticated(
    visableComponentToSystemAdmin(SystemAdminLinks)
  );

  const AdminLinksComp = userIsAuthenticated(
    visableComponentToAdmin(AdminLinks)
  );

  const DistributorLinksComp = userIsAuthenticated(
    visableComponentToDistributor(DistributorLinks)
  );

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          component={Link}
          to={`${url}`}
        >
          <HomeIcon />
        </IconButton>
      </MenuItem>

      <MenuItem>
        <IconButton
          aria-label="show 17 new notifications"
          component={Link}
          to={`${url}/notifications`}
          color="inherit"
        >
          <Badge
            badgeContent={
              notifications.filter((notification) => !notification.isRead)
                .length
            }
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </MenuItem>

      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          onClick={handleProfileClick}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id="primary-search-account-menu"
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleProfileClose}
        >
          <MenuItem
            onClick={handleProfileClose}
            component={Link}
            to={`${url}/profile`}
          >
            Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Hello {user.name}
          </Typography>

          {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            Open Menu
        </Button> */}
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              component={Link}
              to={`${url}`}
            >
              <HomeIcon />
            </IconButton>

            <IconButton
              aria-label="show 17 new notifications"
              edge="end"
              component={Link}
              to={`${url}/notifications`}
              color="inherit"
            >
              <Badge
                badgeContent={
                  notifications.filter((notification) => !notification.isRead)
                    .length
                }
                color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileClick}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              id="primary-search-account-menu"
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorEl)}
              onClose={handleProfileClose}
            >
              <MenuItem
                onClick={handleProfileClose}
                component={Link}
                to={`${url}/profile`}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <SystemAdminLinksComp />
          <AdminLinksComp />
          <DistributorLinksComp />
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        {/* <Home/> */}

        <LoadingOverlay
          active={loading.isLoading && !loading.isLoadingInDialog}
          spinner
          text="Loading your content..."
        >
          <Switch>
            {/* <Route exact path={path} component={userIsAuthenticated(visableComponentToSystemAdmin(SystemAdmin))} /> */}
            <Route
              exact
              path={path}
              component={
                user.type.name == "SystemAdmin"
                  ? AdminsAuth
                  : user.type.name == "Admin"
                  ? DistributorAuth
                  : user.type.name == "Distributor"
                  ? MoviesAssignedToDistributorAuth
                  : Home
              }
            />
            <Route path={`${path}/admins`} component={AdminsAuth} />
            <Route path={`${path}/profile`} component={Profile} />
            <Route path={`${path}/notifications`} component={Notification} />
            <Route path={`${path}/producers`} component={ProducerAuth} />
            <Route path={`${path}/distributors`} component={DistributorAuth} />
            <Route path={`${path}/countries`} component={CountryAuth} />
            <Route path={`${path}/cinemas`} component={CinemaAuth} />
            <Route path={`${path}/screens`} component={ScreenAuth} />
            <Route path={`${path}/movies`} component={MovieAuth} />
            <Route
              path={`${path}/distribute-movies`}
              component={MoviesAssignedToDistributorAuth}
            />

            <Route path={`${path}/history`} component={HistoryAuth} />
            <Route
              path={`${path}/reports/distributors-actions`}
              component={DistributorsActionsAuth}
            />
          </Switch>
        </LoadingOverlay>
      </main>
    </div>
  );
}

const MainPage = connect(mapStateToProps, {
  logout,
  loadUser,
  markUserNotificationAsRead,
  getMovies,
})(MainPageConnect);

export default MainPage;
