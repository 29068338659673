import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import TheatersIcon from "@material-ui/icons/Theaters";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteMovie,
  distributeMovie,
  editMovie,
  sendKdms,
  getMoviesAssignedToUser
} from "../../actions/movieActions";
import MovieCinemaAssignDialog from "./MovieCinemaAssignDialog";
import MovieKDMs from "./MovieKDMs";
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { sendNotification } from "../../helper/notifier";


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return {
    sessionUser: state.auth.user,
    isLoading: state.loading.isLoading,
    token:state.auth.token
  };
};

function MoviesAssignedToDistributorConnect({
  sessionUser,
  getMovies,
  deleteMovie,
  editMovie,
  distributeMovie,
  sendKdms,
  isLoading,
  token
}) {


  const [isActive, setIsActive] = useState(false);

  const [openAssignDialog, setopenAssignDialog] = useState(false);

  const [selectedMovie, setSelectedMovie] = useState("");

  const [selectedRowsNum, setSelectedRowsNum] = useState(0);

  const [distMovies, setDistMovies] = useState([]);

  // useEffect(() => {
  //   getMovies();
  // }, [getMovies]);


  useEffect(()=>{
      getMoviesAssignedToUser(sessionUser.id, token)
        .then((res) => {
          setDistMovies(res.data)
        })
        .catch(err=>console.log(err));
  },[])

  const handleClickAssignOpen = () => {
    setopenAssignDialog(true);
  };

  const handleCloseAssign = () => {
    setopenAssignDialog(false);
    setSelectedMovie("");
  };

  const onClickAssignMovieToCinema = (rowData) => {
    setSelectedMovie(rowData[0]);
    setopenAssignDialog(true);
  };


  const onClickGenerateKDMs = (rowData) => {

    // console.log("cinemaMovies")


    

    rowData.forEach(movie=>{

      const cinemaMovies = []

      // const movieVersions = []

      const distMV = movie?movie.movieVersions.filter(mv=>(mv.users.some((user) => user.id == sessionUser.id))):[]

      for(const mv of distMV){
        if(!mv.dkdm){
            sendNotification(`The Movie ${movie.name} version ${mv.name} has no DKDM`, "error");
          }else{
            mv.cinemas.forEach((cinema) => {
                if (cinema.screens.length == 0) {
                  sendNotification(`The Cinema ${cinema.name} assigned to ${movie.name} version ${mv.name} has no screens`, "error");
                } else {
                  const screensWithNoCertificate = cinema.screens.filter(
                    (screen) =>
                      screen.serverCertificate === null || screen.serverCertificate === ""
                  );
            
                  if (screensWithNoCertificate.length === 0) {
                    // distributeMovie(movie, cinema, sessionUser);
                    cinemaMovies.push(cinema.CinemaMovies)
                  } else if (
                    screensWithNoCertificate.length > 0 &&
                    screensWithNoCertificate.length === cinema.screens.length
                  ) {
                    sendNotification(`The Cinema ${cinema.name} assigned to ${movie.name} version ${mv.name} screens have no server certificates`, "error");
                  } else {
                    sendNotification(`Some Cinema ${cinema.name} assigned to ${movie.name} version ${mv.name} screens have no server certificates`, "warning");
                    // distributeMovie(movie, cinema, sessionUser);
                    cinemaMovies.push(cinema.CinemaMovies)
                  }
                }
          
              });
      }
    }

    // console.log(cinemaMovies)
    distributeMovie(movie,sessionUser,cinemaMovies)

    })



    // if(!movie.dkdm){
    //   sendNotification(`The Movie ${movie.name} has no DKDM`, "error");
    // }else{
    //   rowData.forEach((cinema) => {
    //     if (cinema.screens.length == 0) {
    //       sendNotification(`The Cinema ${cinema.name} has no screens`, "error");
    //     } else {
    //       const screensWithNoCertificate = cinema.screens.filter(
    //         (screen) =>
    //           screen.serverCertificate === null || screen.serverCertificate === ""
    //       );
    
    //       if (screensWithNoCertificate.length === 0) {
    //         distributeMovie(movie, cinema, sessionUser);
    //       } else if (
    //         screensWithNoCertificate.length > 0 &&
    //         screensWithNoCertificate.length === cinema.screens.length
    //       ) {
    //         sendNotification(`Cinema ${cinema.name} screens have no server certificates`, "error");
    //       } else {
    //         sendNotification(`Some Cinema ${cinema.name} screens have no server certificates`, "warning");
    //         distributeMovie(movie, cinema, sessionUser);
    //       }
    //     }
  
    //   });
    // }

   

    
  };

  const onClickSendKdmsToDistributor = (rowData) => {

    rowData.forEach((movie) => {
      sendKdms([],movie,sessionUser,false)
    });

    
  };
 
  
  const onClickSendKdmsToCinema = (rowData) => {

    rowData.forEach(movie=>{

      const cinemaMovies = []

      // const movieVersions = []

      const distMV = movie?movie.movieVersions.filter(mv=>(mv.users.some((user) => user.id == sessionUser.id))):[]

      for(const mv of distMV){
            mv.cinemas.forEach((cinema) => {
                    cinemaMovies.push(cinema.CinemaMovies)
              });
    }

    // console.log(cinemaMovies)
    sendKdms(cinemaMovies,movie,sessionUser,true)

    })

    
  };

  const dynamicLookupObject = { true: "Enabled", false: "Disabled" };

  // const distMovies = movies.filter(movie => {
  //   return movie.movieVersions.filter(mv=>(mv.users.some((user) => user.id == sessionUser.id))).length>0
  // });

  // const distMovies = []

  // console.log(distMovies);
  // const distMovieVersions = movies.filter((movie) =>movie.movieVersions.filter().users.some((user) => user.id == sessionUser.id))
  // const distMovies =  movies.filter((movie) =>
  // movie.users.some((user) => user.id == sessionUser.id))

  return (
    <div>
      <MovieCinemaAssignDialog
        openD={openAssignDialog}
        handleClose={handleCloseAssign}
        movie={selectedMovie}
        sessionUser={sessionUser}
      />

      <MaterialTable
        icons={tableIcons}
        options={{
          selection: true,
          headerStyle: {
            backgroundColor: "#005374",
            color: "#FFF",
          },
        }}
        columns={[
          { title: "Name", field: "name" },
          { title: "Localized Name", field: "localizedName" },
          { title: "Version", field: "version" },
          {
            title: "Status",
            field: "enabled",
            lookup: dynamicLookupObject,
            hidden: true,
          },
          { title: "createdAt", field: "createdAt", type: "datetime" },
          { title: "updatedAt", field: "updatedAt", type: "datetime" },
        ]}
        data={distMovies}
        title="Distribute Movies"
        actions={[
          {
            icon: TheatersIcon,
            tooltip: "Assign Movie to Cinema",
            onClick: (event, rowData) => onClickAssignMovieToCinema(rowData),
            hidden: sessionUser.type.name != "Distributor",
            disabled:
              selectedRowsNum != 1 ||
              !sessionUser.permissions.movies_AssignToCinema,
          },

          // (rowData) => ({
          //   icon: SettingsIcon,
          //   tooltip: "Generate KDMs",
          //   onClick: (event, rowData) => onClickGenerateKDMs(rowData),
          //   hidden: sessionUser.type.name != "Distributor",
          //   disabled:
          //     !sessionUser.permissions.movies_GenerateKdms
          //     //  ||
          //     // rowData.filter(cinema=>(cinema.CinemaMovies.kdmCreated)).length>0,
          // }),

          // (rowData) => (
          //   {
          //   icon: MailIcon,
          //   tooltip: "Send KDMs To Distributor",
          //   onClick: (event, rowData) => onClickSendKdmsToDistributor(rowData),
          //   hidden: sessionUser.type.name != "Distributor",
          //   disabled:
          //     !sessionUser.permissions.movies_SendKdmsNotification 
          //     // ||
          //     // rowData.filter(cinema=>(!cinema.CinemaMovies.kdmCreated)).length>0
          //     // ||rowData.filter(cinema=>(cinema.CinemaMovies.kdmSent)).length>0,
          // }),

          // (rowData) => ({
          //   icon: MailOutlineIcon,
          //   tooltip: "Send KDMs To Cinema",
          //   onClick: (event, rowData) => onClickSendKdmsToCinema(rowData),
          //   hidden: sessionUser.type.name != "Distributor",
          //   disabled:
          //     !sessionUser.permissions.movies_SendKdmsNotification 
          //     // ||
              
          //     // rowData.filter(cinema=>(!cinema.CinemaMovies.kdmCreated)).length>0
          //     // ||rowData.filter(cinema=>(cinema.CinemaMovies.kdmSent)).length>0,
          // }),
        ]}
        onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
        detailPanel={(rowData) => {
          return (
            <MovieKDMs movie={distMovies.find((movie) => movie.id == rowData.id)} />
          );
        }}
      />
    </div>
  );
}

const MoviesAssignedToDistributor = connect(mapStateToProps, {
  deleteMovie,
  editMovie,
  distributeMovie,
  sendKdms
})(MoviesAssignedToDistributorConnect);

export default MoviesAssignedToDistributor;
