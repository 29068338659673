import { Grid } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteMovieVersion,
} from "../../actions/movieActions";
import MovieVersionsDialog from "./MovieVersionsDialog";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return { sessionUser: state.auth.user};
};

function MovieVersionsConnect({ movie,sessionUser,deleteMovieVersion}) {


  const [openDialog, setOpenDialog] = useState(false);

  const [selectedMovieVersion, setSelectedMovieVersion] = useState("");

  const [selectedRowsNum, setSelectedRowsNum] = useState(0);

  const onClickEdit = (rowData) => {
    setSelectedMovieVersion(rowData[0]);
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedMovieVersion("");
  };

  const onClickDelete = (rowData) => {
    rowData.forEach((movieVersion) => {
    //   deleteScreen(user);
    deleteMovieVersion(movie,movieVersion);
    });
  };

//   const onClickEnable = (rowData) => {
//     rowData.forEach((user) => {
//       const updatedScreen = { ...{ id: user.id }, ...{ enabled: true } };
//       editScreen(updatedScreen);
//     });
//   };

//   const onClickDisable = (rowData) => {
//     rowData.forEach((user) => {
//       const updatedScreen = { ...{ id: user.id }, ...{ enabled: false } };
//       editScreen(updatedScreen);
//     });
//   };


  const dynamicLookupObject = { "": "All"};

  // if (cinema.screens.length > 0) {
    return (
      <div>
        <MovieVersionsDialog
        openD={openDialog}
        handleClose={handleClose}
        movie ={movie}
        movieVersion = {selectedMovieVersion}
        sessionUser={sessionUser}
      />
        <br />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={6}>
            <MaterialTable
              icons={tableIcons}
              options={{
                selection: true,
                headerStyle: {},
              }}
              columns={[
                { title: "Name", field: "name" },
                { title: "Country", field: "country.name" }
              ]}
              data={movie.movieVersions}
              title={movie.name + " Versions"}
              actions={[
                {
                  icon: Edit,
                  tooltip: "Edit Version",
                  isFreeAction: false,
                  onClick: (event, rowData) => onClickEdit(rowData),
                  disabled:
                    selectedRowsNum != 1 || !sessionUser.permissions.movies_Edit,
                },
                (rowData) => ({
                  icon: Delete,
                  tooltip: "Delete Version",
                  onClick: (event, rowData) => onClickDelete(rowData),
                  disabled: !sessionUser.permissions.movies_Delete|| selectedRowsNum == movie.movieVersions.length|| rowData[0].isMainVersion
                  ,
                }),
                {
                  icon: Add,
                  tooltip: "Add Version",
                  isFreeAction: true,
                  onClick: (event) => handleClickOpen(),
                  disabled: !sessionUser.permissions.movies_Add,
                },
              ]}
              onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
            />
          </Grid>
        </Grid>
        <br />
      </div>
    );
  }
//   return (
//     <div>
//       <Grid container justify="center" alignItems="center">
//         <Grid item xs={12}>
//           <p>No Records to display</p>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

const MovieVersions = connect(mapStateToProps, {
  deleteMovieVersion})(MovieVersionsConnect);

export default MovieVersions;
