import { GET_ERRORS, CLEAR_ERRORS, FINISHED_LOADING } from "./types";
import {
  success,
  error,
  warning,
  info,
  removeAll,
} from "react-notification-system-redux";

// const notificationOpts = {
//   // uid: 'once-please', // you can specify your own uid if required
//   title: 'Hey, it\'s good to see you!',
//   message: 'Now you can see how easy it is to use notifications in React!',
//   position: 'tr',
//   autoDismiss: 0,
//   action: {
//     label: 'Click me!!',
//     callback: () => alert('clicked!')
//   }
// };

// RETURN ERRORS
export const returnErrors = (msg, status, notify, id = null) => (dispatch) => {
  if (notify) {
    const notificationOpts = {
      // uid: 'once-please', // you can specify your own uid if required
      title: "Error",
      message: msg.msg,
      position: "tr",
      autoDismiss: 5,
    };

    dispatch(error(notificationOpts));
    dispatch({
      type: FINISHED_LOADING,
      payload: true,
    });
  }
  return {
    type: GET_ERRORS,
    payload: { msg, status, id },
  };
};

// CLEAR ERRORS
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
