import Add from "@material-ui/icons/Add";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CancelIcon from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getDistributorsActions
} from "../../actions/reportsActions";
import DateFnsAdapter from "@date-io/date-fns";

import DistributorsActionsDetails from "./DistributorsActionsDetails";

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { compareDesc } from "date-fns";

const dateFns = new DateFnsAdapter();

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return {  distributorsActions:state.report.distributorsActions ,sessionUser: state.auth.user };
};

function DistributorsActionsConnect({
  sessionUser,
  distributorsActions,
  getDistributorsActions,

}) {
  useEffect(() => {
    getDistributorsActions();
  }, [getDistributorsActions]);

  distributorsActions.sort((a, b) =>
  compareDesc(dateFns.date(a.createdAt), dateFns.date(b.createdAt))
);


  const dynamicLookupObject = { true: "Enabled", false: "Disabled" };

//   userId: DataTypes.INTEGER,
//   username: DataTypes.STRING,
//   action: DataTypes.STRING,
//   movieId: DataTypes.INTEGER,
//   movie: DataTypes.STRING,
//   movieVersionId: DataTypes.INTEGER,
//   movieVersion: DataTypes.STRING,
//   cinemaId: DataTypes.INTEGER,
//   cinema: DataTypes.STRING,
//   startDate:DataTypes.DATE,
//   endDate:DataTypes.DATE

    const exportAsPDF = ()=>{

    const data = distributorsActions.map(action=>(Object.values(action)));
    console.log(data);
    const doc = new jsPDF()

    doc.autoTable({
        head: [['createdAt', 'Name', 'Action','Movie', 'Version', 'Cinema','Start Date', 'End Date']],
        body: data
      })
       
      doc.save('table.pdf')

    }

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        options={{
          selection: false,
          headerStyle: {
            backgroundColor: "#005374",
            color: "#FFF",
          },
          // exportButton: true,
          // exportAllData:true,
          filtering: true
        //     exportCsv: (columns, data) => {
        //         // alert('You should develop a code to export ' + data.length + ' rows');
        //         exportAsPDF(data);
        //       }
        }}
        columns={[
          { title: "createdAt", field: "createdAt", type: "datetime" ,filtering: false},
          { title: "Name", field: "username" },
          { title: "Action", field: "action" },
          { title: "Movie", field: "movie" },
          { title: "KDMs Sent To", field: "sentTo" },
          // { title: "Version", field: "movieVersion" },
          // {title: "Cinema",field: "cinema",},
          // { title: "Start Date", field: "startDate", type: "datetime",filtering: false },          
          // { title: "End Date", field: "endDate", type: "datetime",filtering: false },
        ]}
        data={distributorsActions}
        title="Distributors Actions Report"
        // actions={[
        //     {
        //       icon: SaveAltIcon,
        //       tooltip: "Download Report",
        //       isFreeAction: true,
        //       onClick: (event) => exportAsPDF(),
        //     },
        //   ]}
        // onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
        detailPanel={(rowData) => {
          return (
            <DistributorsActionsDetails
              distributorsAction={rowData}
            />
          );
        }}
      />
    </div>
  );
}

const DistributorsActions = connect(mapStateToProps, {
    getDistributorsActions
})(DistributorsActionsConnect);

export default DistributorsActions;
