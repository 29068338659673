import React, { useState, useEffect } from 'react';
import './App.css';
import Main from './components/Main';
import store from "./store/index";
import { loadUser } from './actions/authActions';
import { BrowserRouter as Router, Route, NavLink, Switch, BrowserRouter } from 'react-router-dom'


function App() {

  useEffect(() => {
    // Update the document title using the browser API
    store.dispatch(loadUser());
  });

  return (
    <div>
      <BrowserRouter>
    <Main/>
    </BrowserRouter>
    </div>
  );
}

export default App;
 