import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import MaterialUiTextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as _forEach from "lodash/forEach";
import * as _union from "lodash/union";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addUser, editUser } from "../../actions/userActions";
import { getCountries } from "../../actions/countryActions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as _differenceWith from "lodash/differenceWith";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return { countries: state.country.countries };
};

function DistributorDialogConnect({
  user,
  addUser,
  editUser,
  openD,
  handleClose,
  getCountries,
  countries,
}) {
  useEffect(() => {
    if(openD){
      getCountries();
    }
    
  }, [openD]);

  // console.log(countries);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;


  const email = Yup.object({
    id: Yup.number().nullable(),
    email: Yup.string().email("Invalid email")
  });

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password: user
      ? Yup.string().min(2, "Too Short!").max(50, "Too Long!")
      : Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),

    email2: Yup.string().email("Invalid email"),
    name: Yup.string().required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    countries: Yup.array().required("Required"),
    emails: Yup.array().of(email).nullable()
  });

  const [showPassword, setShowPassword] = useState(false);

  const closeDialog = () => {
    handleClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    const finalValues = {
      ...values,
      ...{
        permissions: convertValueArrayToPermissionsObjects(values.permissions),
      },
    };

    if (user) {
      const toBeRemovedValues = _differenceWith(
        user.countries,
        values.countries,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      const toBeAddedValues = _differenceWith(
        values.countries,
        user.countries,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      finalValues.countries = toBeAddedValues;
      finalValues.removedCountries = toBeRemovedValues;



      const toBeRemovedEmails = _differenceWith(
        user.emails,
        values.emails,
        (arrValue, othValue) => arrValue.email == othValue.email
      );

      const toBeAddedEmails = _differenceWith(
        values.emails,
        user.emails,
        (arrValue, othValue) => arrValue.email == othValue.email
      );

      finalValues.emails = toBeAddedEmails;
      finalValues.removedEmails = toBeRemovedEmails;

      if (finalValues.password == "") {
        delete finalValues.password;
      }

      // console.log(finalValues)

      editUser(finalValues);
    } else {

      // console.log(finalValues)
      addUser(finalValues);
    }

    setSubmitting(false);
    closeDialog();
  };

  const userPermissions = {
    movies_AssignToCinema: false,
    movies_GenerateKdms: false,
    movies_SendKdmsNotification: false,
    movies_Request: false,
  };

  const handleSelectAll = (permissions, isChecked, setFieldValue) => {
    if (isChecked) {
      permissions.forEach((perm) => {
        perm.values = _union(perm.values, perm.options);
      });
    } else {
      permissions.forEach((perm) => {
        perm.values = [];
      });
    }

    setFieldValue("values.permissions", permissions);
  };

  const permToTextMap = {
    AssignToCinema: "Assign Movie To Cinema",
    GenerateKdms: "Generate KDMs",
    SendKdmsNotification: "Send KDMs Notification",
    Request: "Request Movies for Distribution",
  };

  const textToPermMap = {
    "Assign Movie To Cinema": "AssignToCinema",
    "Generate KDMs": "GenerateKdms",
    "Send KDMs Notification": "SendKdmsNotification",
    "Request Movies for Distribution": "Request",
  };

  const convertPermissionsObjectToValueArray = (permissions) => {
    const transformedPermissions = [
      {
        type: "movies",
        options: [
          "Assign Movie To Cinema",
          "Generate KDMs",
          "Send KDMs Notification",
          "Request Movies for Distribution",
        ],
        values: [],
      },
    ];

    _forEach(permissions, function (value, key) {
      const splitted = key.split("_");

      const type = splitted[0];

      if (value) {
        transformedPermissions.forEach((perm) => {
          if (perm.type == type) {
            perm.values.push(
              permToTextMap[splitted[1]]
                ? permToTextMap[splitted[1]]
                : splitted[1]
            );
          }
        });
      }
    });

    return transformedPermissions;
  };

  const convertValueArrayToPermissionsObjects = (transformedPermissions) => {
    const userPermissions = {
      movies_AssignToCinema: false,
      movies_GenerateKdms: false,
      movies_SendKdmsNotification: false,
      movies_Request: false,
    };

    transformedPermissions.forEach((tperm) => {
      tperm.values.forEach((value) => {
        userPermissions[
          `${tperm.type}_${textToPermMap[value] ? textToPermMap[value] : value}`
        ] = true;
      });
    });

    return userPermissions;
  };

  const initialValues = user
    ? {
        ...user,
        ...{
          permissions: convertPermissionsObjectToValueArray(user.permissions),
        },
        ...{ password: "" },
      }
    : {
        username: "",
        password: "",
        email: "",
        email2: "",
        name: "",
        phone: "",
        enabled: true,
        type: "Distributor",
        permissions: convertPermissionsObjectToValueArray(userPermissions),
        countries: [],
        emails: [],
      };


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              handleBlur,
            }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Username"
                      required
                      type="text"
                      name="username"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required={user ? false : true}
                      label={user ? "Reset Password" : "Password"}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword && <Visibility />}
                              {!showPassword && <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <ErrorMessage name="password" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label="Main Email"
                      type="email"
                      name="email"
                    />
                    {/* <ErrorMessage name="email" component="div" /> */}
                  </Grid>

                  {/* <Grid item xs={2} hidden>
                    <Field
                      component={TextField}
                      label="Email 2"
                      type="email"
                      name="email2"
                      
                    />
                  </Grid> */}

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name"
                      type="text"
                      name="name"
                      required
                    />
                    {/* <ErrorMessage name="name" component="div" /> */}
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Phone"
                      type="tel"
                      name="phone"
                    />
                    {/* <ErrorMessage name="phone" component="div" /> */}
                  </Grid>

                  <Grid item xs={3}>
                    <Autocomplete
                      multiple
                      // filterSelectedOptions
                      required
                      disableCloseOnSelect
                      name={`countries`}
                      options={countries}
                      value={values.countries}
                      getOptionSelected={(option, value) => {
                        return option.id == value.id;
                      }}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        setFieldValue(`countries`, value);
                      }}
                      onClose={handleBlur}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            color="primary"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <MaterialUiTextField
                          label={`Restrict Distributor to Countries`}
                          fullWidth
                          error={Boolean(touched.countries && errors.countries)}
                          helperText={touched.countries && errors.countries}
                          name={`countries`}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="flex-end"
                  >
                    {/* <Field
                      component={CheckboxWithLabel}
                      name="enabled"
                      color="primary"
                      type="checkbox"
                      Label={{ label: "Enabled" }}
                    /> */}
                    {/* <ErrorMessage name="enabled" component="div" /> */}
                  </Grid>
                </Grid>

                <hr />

                <br />
                {/* <h5>Additional Emails</h5> */}

                <Grid item xs={12}>
                  <FieldArray
                    name="emails"
                    render={(arrayHelpers) => (
                      <div>
                        {/* <p>Additional Emails</p> */}

                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="h6" noWrap>
                              Additional Emails
                              <IconButton
                                edge="end"
                                aria-label="add"
                                onClick={() => arrayHelpers.push({ email: "" })}
                                // disabled={values.emails.length >= 5}
                              >
                                <AddIcon />
                              </IconButton>
                            </Typography>
                          </Grid>

                          {/* <Grid item xs={3}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => arrayHelpers.push({ email: "" })}
                          disabled={values.emails.length >= 5}
                        >
                          
                          <AddIcon />
                        </IconButton>
                        </Grid> */}
                        </Grid>

                        {values.emails.map((email, index) => (
                          <div key={index}>
                            {/** both these conventions do the same */}

                            <Grid container direction="row" alignItems="center">
                              <Grid item>
                                <Field
                                  name={`emails[${index}].email`}
                                  component={TextField}
                                  label="Email"
                                  type="email"
                                />
                              </Grid>
                              <Grid item>
                                <IconButton
                                  color="secondary"
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </Grid>
                <hr />

                <br />
                {/* <h5>Permissions</h5> */}
                <Typography variant="h6">Permissions</Typography>
                {/* <br /> */}

                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="selectAll"
                        onChange={(event) => {
                          handleSelectAll(
                            values.permissions,
                            event.target.checked,
                            setFieldValue
                          );
                        }}
                      />
                    }
                    label="Select All"
                    labelPlacement="end"
                  />
                  {/* <Field component={CheckboxWithLabel} name="selectAll" type="checkbox" Label={{ label: 'Select All Permissions' }} 
                    onChange={(event)=>{

                      console.log('here')
                      handleSelectAll(values.permissions)
                      if(event.target.checked){

                      }else{

                      }

                      



                    }} /> */}
                  {/* <ErrorMessage name="enabled" component="div" /> */}
                </Grid>

                <FieldArray
                  name="permissions"
                  render={(arrayHelpers) => (
                    <Grid container spacing={3}>
                      {values.permissions.map((perm, index) => (
                        <Grid key={index} item xs={3}>
                          {/* {console.log(perm)} */}
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            name={`permissions.${index}.values`}
                            options={perm.options}
                            value={values.permissions[index].values}
                            getOptionSelected={(option, value) => {
                              return option == value;
                            }}
                            onChange={(e, value) => {
                              setFieldValue(
                                `permissions.${index}.values`,
                                value
                              );
                            }}
                            renderInput={(params) => (
                              <MaterialUiTextField
                                label={`${perm.type} Permissions`}
                                fullWidth
                                name={`permissions.${index}.values`}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                />
                {/* </Grid> */}

                <br />

                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const DistributorDialog = connect(mapStateToProps, {
  addUser,
  editUser,
  getCountries,
})(DistributorDialogConnect);

export default DistributorDialog;
