import {
  default as DateFnsAdapter,
  default as DateFnsUtils,
} from "@date-io/date-fns";

import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { duration, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, FieldArray, Form, Formik } from "formik";
import { DateTimePicker } from "formik-material-ui-pickers";
import * as _concat from "lodash/concat";
import * as _differenceWith from "lodash/differenceWith";
import * as _reject from "lodash/reject";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { getCinemas } from "../../actions/cinemaActions";
import { getCountries } from "../../actions/countryActions";
import { addMovie, editMovie, assignMovie,getMovieVersionsAssignedToUser } from "../../actions/movieActions";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MaterialUiTextField from "@material-ui/core/TextField";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { returnErrors } from "../../actions/errorActions";
// var differenceInCalendarDays = require('date-fns/differenceInCalendarDays')
import { startLoadingInDialog,finishLoadingInDialog } from "../../actions/loadingActions";
import LoadingOverlay from "react-loading-overlay";





const dateFns = new DateFnsAdapter();



const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const mapStateToProps = (state) => {
  return {cinemas: state.cinema.cinemas, countries: state.country.countries, token:state.auth.token,isLoadingInDialog: state.loading.isLoadingInDialog  };
};

function MovieCinemaAssignDialogConnect({
  movie,
  cinemas,
  getCinemas,
  countries,
  getCountries,
  sessionUser,
  addMovie,
  editMovie,
  openD,
  handleClose,
  assignMovie,
  token,
  returnErrors,
  startLoadingInDialog,
  finishLoadingInDialog,
  isLoadingInDialog
}) {

  useEffect(() => {
    if(openD){
      getCinemas();
      getCountries();
    }

  }, [openD]);

  const [mvs, setMvs] = useState([]);


  useEffect(()=>{
    if (movie&&movie!="") {
      startLoadingInDialog()
    getMovieVersionsAssignedToUser(movie.id,sessionUser.id, token)
      .then((res) => {
        setMvs(res.data)
        finishLoadingInDialog()
      })
      .catch(err=>{console.log(err)
        returnErrors(err.response.data, err.response.status, true)
      });
    }
},[movie])

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const schema = Yup.object().shape({
    movieVersion: Yup.object().required("Required").nullable(),
    cinemas: Yup.array().required("Required").nullable()
  });

  const classes = useStyles();

  const [selectedCountries, setSelectedCountries] = useState([]);

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    // console.log(values);
    assignMovie(movie, values);
    setSubmitting(false);
    closeDialog();
  };

  const handleSelectAll = (isChecked, setFieldValue) => {
    if (isChecked) {
      setFieldValue("cinemas", cinemaOptions);

    } else {

      setFieldValue("cinemas", []);


    }

  };


  const countryOptions = sessionUser.countries ? sessionUser.countries.length > 0 ? sessionUser.countries : countries : countries;

  const cinemaOptions = selectedCountries.length > 0 ? cinemas.filter(cinema => (selectedCountries.some(country => country.id == cinema.country.id))) : []
  // console.log(cinemaOptions);
  // const cinemaOptions = cinemas.filter(cinema=>(selectedCountries.some(country=>country.id == cinema.country.id)))

  cinemaOptions.sort((a, b) =>
  (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
);

  // const mvs = movie ? movie.movieVersions.filter(mv => (mv.users.some((user) => user.id == sessionUser.id))) : []

  console.log(mvs)

  const initialValues = {
    movieVersion: null,
    countries: [],
    cinemas: [],
    startDate: new Date(),
    endDate: dateFns.addDays(new Date(), 30),
    duration: `30 Days`
  };

  return (
    <div>
      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          open={openD}
          onClose={closeDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <LoadingOverlay
          active={isLoadingInDialog}
          spinner
          text="Loading your content..."
        >
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title">Details</DialogTitle>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={closeDialog}
                edge="end"
                type="button"
              >
                <CloseIcon></CloseIcon>
              </IconButton>
            </Grid>
          </Grid>

          <DialogContent>
          
            <Grid container spacing={1}>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleOnSubmit}
                // enableReinitialize
              >
                {({ isSubmitting, values, setFieldValue, touched, errors, handleBlur }) => (
                  <Form>
                    <Grid container alignItems="center" spacing={3}>

                      <Grid item xs={4}>
                        <Autocomplete
                          // multiple
                          // filterSelectedOptions
                          fullWidth
                          disableCloseOnSelect
                          name={`movieVersion`}
                          options={mvs}
                          value={values.movieVersion}
                          getOptionSelected={(option, value) => {
                            return option.id == value.id;
                          }}
                          getOptionLabel={(option) => option.country.name}
                          onChange={(e, value) => {
                            console.log(value)
                            setFieldValue(`movieVersion`, value);

                            setFieldValue(`cinemas`, []);
                            const count = [];
                            if (value) {
                              count.push(value.country)
                            }
                            // value.forEach(mv=>{count.push(mv.country)})
                            setSelectedCountries(count);

                          }}
                          onClose={handleBlur}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                color="primary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              { option.country.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <MaterialUiTextField
                              label={`Choose Movie Version`}
                              fullWidth
                              error={Boolean(touched.movieVersion && errors.movieVersion)}
                              helperText={touched.movieVersion && errors.movieVersion}
                              name={`movieVersion`}
                              {...params}
                            />
                          )}
                        />
                      </Grid>

                      {/* <Grid item xs={5}>
                          <Autocomplete
                            multiple
                            // filterSelectedOptions
                            disableCloseOnSelect
                            name={`countries`}
                            options={countryOptions}
                            value={values.countries}
                            getOptionSelected={(option, value) => {
                              return option.id == value.id;
                            }}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                              setFieldValue(`countries`, value);
                              setSelectedCountries(value);
                              setFieldValue(`cinemas`, []);
                            }}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                color="primary"
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <MaterialUiTextField
                                label={`Choose Countries of Distribution`}
                                fullWidth
                                name={`countries`}
                                {...params}
                              />
                            )}
                          />
                    </Grid> */}


                      <Grid item xs={5}>
                        <Autocomplete
                          multiple
                          fullWidth
                          style={{ maxWidth: 500 }}
                          // filterSelectedOptions
                          disableCloseOnSelect
                          limitTags={3}
                          name={`cinemas`}
                          options={cinemaOptions}
                          value={values.cinemas}
                          getOptionSelected={(option, value) => {
                            return option.id == value.id;
                          }}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => {
                            setFieldValue(`cinemas`, value);
                            // setSelectedCountries(value);
                          }}
                          onClose={handleBlur}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                color="primary"
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <MaterialUiTextField
                              label={`Choose Cinemas`}
                              fullWidth
                              error={Boolean(touched.cinemas && errors.cinemas)}
                              helperText={touched.cinemas && errors.cinemas}
                              name={`cinemas`}
                              {...params}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel

                          style={{ marginBottom: '0px', marginTop:'35px' }}
                          control={
                            <Checkbox
                              color="primary"
                              name="selectAll"
                              onChange={(event) => {
                                handleSelectAll(
                                  event.target.checked,
                                  setFieldValue
                                );
                              }}
                            />
                          }
                          label="Select All Cinemas"
                          labelPlacement="end"
                        />

                      </Grid>

                      <br />

                      <Grid item xs={4}>
                        <Field
                          component={DateTimePicker}
                          label="Start Date"
                          name={`startDate`}
                          ampm={false}
                          disablePast
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Field
                          component={DateTimePicker}
                          label="End Date"
                          name={`endDate`}
                          ampm={false}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Field
                          component={TextField}
                          label="Duration"
                          fullWidth
                          type="text"
                          name="duration"
                          value={`${differenceInCalendarDays(values.endDate, values.startDate)} Days`}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {/* <ErrorMessage name="username" component="div" /> */}
                      </Grid>

                    </Grid>
                    <br />
                    <Button color="primary" type="submit">
                      Save
                </Button>

                    <Button onClick={closeDialog} color="primary">
                      Cancel
                </Button>
                  </Form>
                )}
              </Formik>
            </Grid>
          </DialogContent>
          </LoadingOverlay>
        </Dialog>
      </MuiPickersUtilsProvider>
      
    </div>
  );
}

const MovieCinemaAssignDialog = connect(mapStateToProps, {
  addMovie,
  editMovie,
  getCinemas,
  getCountries,
  assignMovie,
  returnErrors,
  startLoadingInDialog,finishLoadingInDialog
})(MovieCinemaAssignDialogConnect);

export default MovieCinemaAssignDialog;
