import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addUser, editUser } from "../../actions/userActions";

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return {};
};

function ProducerDialogConnect({
  user,
  addUser,
  editUser,
  openD,
  handleClose,
}) {
  const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    email: Yup.string().email("Invalid email"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });

  const closeDialog = () => {
    handleClose();
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (user) {
      if (values.name !== user.name) {
        values.username = values.name;
      }

      editUser(values);
    } else {
      values.username = values.name;
      values.password = values.name;

      addUser(values);
    }

    setSubmitting(false);
    closeDialog();
  };

  const initialValues = user
    ? user
    : {
        username: "",
        password: "",
        email: "",
        name: "",
        phone: "",
        enabled: true,
        type: "Producer",
      };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="start"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      required
                      label="Name"
                      type="text"
                      name="name"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Email"
                      type="email"
                      name="email"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Phone"
                      type="tel"
                      name="phone"
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="flex-end"
                  >
                    {/* <Field
                      component={CheckboxWithLabel}
                      name="enabled"
                      color="primary"
                      type="checkbox"
                      Label={{ label: "Enabled" }}
                    /> */}
                  </Grid>
                </Grid>

                <br />

                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ProducerDialog = connect(mapStateToProps, { addUser, editUser })(
  ProducerDialogConnect
);

export default ProducerDialog;
