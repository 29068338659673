import { Grid } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteScreen,
  editScreen,
  getScreens,
} from "../../actions/screenActions";
import CinemaScreensDialog from "./CinemaScreensDialog";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return { sessionUser: state.auth.user, isLoading: state.loading.isLoading };
};

function CinemaScreensConnect({ cinema, sessionUser,deleteScreen,editScreen }) {


  const [openDialog, setOpenDialog] = useState(false);

  const [selectedScreen, setSelectedScreen] = useState("");

  const [selectedRowsNum, setSelectedRowsNum] = useState(0);

  const onClickEdit = (rowData) => {
    setSelectedScreen(rowData[0]);
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedScreen("");
  };

  const onClickDelete = (rowData) => {
    rowData.forEach((user) => {
      deleteScreen(user);
    });
  };

  const onClickEnable = (rowData) => {
    rowData.forEach((user) => {
      const updatedScreen = { ...{ id: user.id }, ...{ enabled: true } };
      editScreen(updatedScreen);
    });
  };

  const onClickDisable = (rowData) => {
    rowData.forEach((user) => {
      const updatedScreen = { ...{ id: user.id }, ...{ enabled: false } };
      editScreen(updatedScreen);
    });
  };


  const dynamicLookupObject = { true: "Enabled", false: "Disabled" };

  // if (cinema.screens.length > 0) {
    return (
      <div>
        <CinemaScreensDialog
        openD={openDialog}
        handleClose={handleClose}
        screen={selectedScreen}
        cinema ={cinema}
      />
        <br />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={6}>
            <MaterialTable
              icons={tableIcons}
              options={{
                selection: true,
                headerStyle: {},
              }}
              columns={[
                { title: "Name", field: "name" },
               
              ]}
              data={cinema.screens}
              title={cinema.name + " Screens"}
              actions={[
                {
                  icon: Edit,
                  tooltip: "Edit Screen",
                  isFreeAction: false,
                  onClick: (event, rowData) => onClickEdit(rowData),
                  disabled:
                    selectedRowsNum != 1 || !sessionUser.permissions.screens_Edit,
                },
                {
                  icon: Delete,
                  tooltip: "Delete Screen",
                  onClick: (event, rowData) => onClickDelete(rowData),
                  disabled: !sessionUser.permissions.screens_Delete,
                },
                {
                  icon: CheckCircleIcon,
                  tooltip: "Enable Screen",
                  onClick: (event, rowData) => onClickEnable(rowData),
                  disabled: !sessionUser.permissions.screens_EnableOrDisable,
                  hidden: true,
                },
                {
                  icon: CancelIcon,
                  tooltip: "Disable Screen",
                  onClick: (event, rowData) => onClickDisable(rowData),
                  disabled: !sessionUser.permissions.screens_EnableOrDisable,
                  hidden: true,
                },
                {
                  icon: Add,
                  tooltip: "Add Screen",
                  isFreeAction: true,
                  onClick: (event) => handleClickOpen(),
                  disabled: !sessionUser.permissions.screens_Add,
                },
              ]}
              onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
            />
          </Grid>
        </Grid>
        <br />
      </div>
    );
  }
//   return (
//     <div>
//       <Grid container justify="center" alignItems="center">
//         <Grid item xs={12}>
//           <p>No Records to display</p>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

const CinemaScreens = connect(mapStateToProps, {
  deleteScreen,
  editScreen})(CinemaScreensConnect);

export default CinemaScreens;
