import axios from "axios";
import {
  GET_CINEMAS,
  EDIT_CINEMA,
  DELETE_CINEMA,
  ADD_CINEMA,
  IS_LOADING,
  FINISHED_LOADING,
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";
import { sendNotification } from "../helper/notifier";

// import { IItem } from '../../types/interfaces';

export const getCinemas = () => (dispatch, getState) => {
  // console.log("inside actions")
  dispatch({
    type: IS_LOADING,
    payload: true,
  });
  axios
    .get("/api/cinemas", tokenConfig(getState))
    .then((res) => {
      // console.log(res)
      dispatch({
        type: GET_CINEMAS,
        payload: res.data,
      });

      dispatch({
        type: FINISHED_LOADING,
        payload: true,
      });
      // sendNotification('Users fetched Successfully','success');
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const addCinema = (cinema) => (dispatch, getState) => {
  // console.log(cinema)
  dispatch({
    type: IS_LOADING,
    payload: true,
  });

  const files = cinema.certificates;
  delete cinema.certificates;

  const formData = new FormData();
  Object.keys(cinema).forEach((key) => formData.append(key, cinema[key]));

  if (files) {
    for (var x = 0; x < files.length; x++) {
      formData.append("certificates", files[x]);
    }
  }

  axios
    .post("/api/cinemas", formData, tokenConfig(getState))
    .then((res) => {
      // console.log(res)
      dispatch({
        type: ADD_CINEMA,
        payload: res.data,
      });

      dispatch({
        type: FINISHED_LOADING,
        payload: true,
      });

      sendNotification("Cinema Added Successfully", "success");
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status, true))
    );
};

export const editCinema = (cinema) => (dispatch, getState) => {
  const id = cinema.id;
  delete cinema.id;

  dispatch({
    type: IS_LOADING,
    payload: true,
  });

  const files = cinema.certificates;
  delete cinema.certificates;

  const formData = new FormData();
  Object.keys(cinema).forEach((key) => formData.append(key, cinema[key]));

  if (files) {
    for (var x = 0; x < files.length; x++) {
      formData.append("certificates", files[x]);
    }
  }
  axios
    .put("/api/cinemas/" + id, formData, tokenConfig(getState))
    .then((res) => {
      // console.log(res)
      dispatch({
        type: EDIT_CINEMA,
        payload: res.data,
      });

      dispatch({
        type: FINISHED_LOADING,
        payload: true,
      });
      sendNotification("Cinema Edited Successfully", "success");
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status, true))
    );
};

export const deleteCinema = (cinema) => (dispatch, getState) => {
  // console.log("inside actions")
  dispatch({
    type: IS_LOADING,
    payload: true,
  });
  axios
    .delete("/api/cinemas/" + cinema.id, tokenConfig(getState))
    .then((res) => {
      // console.log(res)
      dispatch({
        type: DELETE_CINEMA,
        payload: res.data,
      });
      dispatch({
        type: FINISHED_LOADING,
        payload: true,
      });
      sendNotification("Cinema Deleted Successfully", "success");
    })
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status, true))
    );
};
