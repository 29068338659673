import { Grid } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MaterialTable from "material-table";
import React, { forwardRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { distributeMovie,sendKdms,deassignMovie,getMovieVersionsAssignedToUser } from "../../actions/movieActions";
import { sendNotification } from "../../helper/notifier";
import Delete from "@material-ui/icons/Delete";
import { returnErrors } from "../../actions/errorActions";
import { startLoading,finishLoading } from "../../actions/loadingActions";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mapStateToProps = (state) => {
  return { sessionUser: state.auth.user, isLoading: state.loading.isLoading, token:state.auth.token };
};

function MovieKDMsConnect({ movie, sessionUser, distributeMovie,sendKdms,deassignMovie, token,returnErrors,startLoading,finishLoading }) {
  const [isActive, setIsActive] = useState(false);

  const [selectedMovie, setSelectedMovie] = useState("");

  const [selectedRowsNum, setSelectedRowsNum] = useState(0);

  const [distMV, setDistMV] = useState([]);

  useEffect(()=>{
    startLoading()
    getMovieVersionsAssignedToUser(movie.id,sessionUser.id, token)
      .then((res) => {
        setDistMV(res.data)
        finishLoading()
      })
      .catch(err=>{console.log(err)
        returnErrors(err.response.data, err.response.status, true)
      });
},[])



  


  const onClickDistributeMovie = (rowData,sendKdmToCinema) => {

    const cinemaMovies = [] 

    rowData.forEach(cinema=>{
      cinemaMovies.push(cinema.CinemaMovies);
    });


    distributeMovie(movie,sessionUser,cinemaMovies,sendKdmToCinema)


    // if(!movie.dkdm){
    //   sendNotification(`The Movie ${movie.name} has no DKDM`, "error");
    // }else{
    //   rowData.forEach((cinema) => {
    //     if (cinema.screens.length == 0) {
    //       sendNotification(`The Cinema ${cinema.name} has no screens`, "error");
    //     } else {
    //       const screensWithNoCertificate = cinema.screens.filter(
    //         (screen) =>
    //           screen.serverCertificate === null || screen.serverCertificate === ""
    //       );
    
    //       if (screensWithNoCertificate.length === 0) {
    //         distributeMovie(movie, cinema, sessionUser);
    //       } else if (
    //         screensWithNoCertificate.length > 0 &&
    //         screensWithNoCertificate.length === cinema.screens.length
    //       ) {
    //         sendNotification(`Cinema ${cinema.name} screens have no server certificates`, "error");
    //       } else {
    //         sendNotification(`Some Cinema ${cinema.name} screens have no server certificates`, "warning");
    //         distributeMovie(movie, cinema, sessionUser);
    //       }
    //     }
  
    //   });
    // }
    
  };

  const onClickDeassignCinemaMovies = (rowData) => {

    const cinemaMovies = [] 

    rowData.forEach(cinema=>{
      cinemaMovies.push(cinema.CinemaMovies);
    });

    deassignMovie(movie,cinemaMovies);
  };

  // const onClickGenerateKDMs = (rowData) => {

  //   const cinemaMovies = [] 

  //   rowData.forEach(cinema=>{
  //     cinemaMovies.push(cinema.CinemaMovies);
  //   });


  //   distributeMovie(movie,sessionUser,cinemaMovies)


  //   // if(!movie.dkdm){
  //   //   sendNotification(`The Movie ${movie.name} has no DKDM`, "error");
  //   // }else{
  //   //   rowData.forEach((cinema) => {
  //   //     if (cinema.screens.length == 0) {
  //   //       sendNotification(`The Cinema ${cinema.name} has no screens`, "error");
  //   //     } else {
  //   //       const screensWithNoCertificate = cinema.screens.filter(
  //   //         (screen) =>
  //   //           screen.serverCertificate === null || screen.serverCertificate === ""
  //   //       );
    
  //   //       if (screensWithNoCertificate.length === 0) {
  //   //         distributeMovie(movie, cinema, sessionUser);
  //   //       } else if (
  //   //         screensWithNoCertificate.length > 0 &&
  //   //         screensWithNoCertificate.length === cinema.screens.length
  //   //       ) {
  //   //         sendNotification(`Cinema ${cinema.name} screens have no server certificates`, "error");
  //   //       } else {
  //   //         sendNotification(`Some Cinema ${cinema.name} screens have no server certificates`, "warning");
  //   //         distributeMovie(movie, cinema, sessionUser);
  //   //       }
  //   //     }
  
  //   //   });
  //   // }
    
  // };

  // const onClickSendKdmsToDistributor = (rowData) => {

  //   rowData.forEach((cinema) => {

  //     sendKdms(movie,cinema,sessionUser,false)
  //     // const cinemaMovie = cinema.movies.find(mov=>(mov.id === movie.id)).CinemaMovies;

  //     // if(cinemaMovie.)
  //   });

    
  // };

  
  // const onClickSendKdmsToCinema = (rowData) => {

  //   const cinemaMovies = []


  //   rowData.forEach((cinema) => {

  //     if(!cinema.KdmEmail){
  //       sendNotification(`Cinema ${cinema.name} has no KDM email`, "error");
  //     }else{
  //       cinemaMovies.push(cinema.CinemaMovies);
  //     }
      
  //     // const cinemaMovie = cinema.movies.find(mov=>(mov.id === movie.id)).CinemaMovies;

  //     // if(cinemaMovie.)
  //   });

  //   sendKdms(cinemaMovies,movie,sessionUser,true)
  // };

  const dynamicLookupObject = { true: "Yes", false: "No", null:"No" };

  // const distMV = movie?movie.movieVersions.filter(mv=>(mv.users.some((user) => user.id == sessionUser.id))):[]

  const dynamicLookupMV = {}

  distMV.forEach(mv => {
    dynamicLookupMV[mv.id]=mv.name
  });


  const dynamicLookupCountry = {}

  distMV.forEach(mv => {
    dynamicLookupCountry[mv.id]=mv.country?mv.country.name:""
  });

  // console.log(distMV)

  // const mvCinemas = distMV.map(mv=>mv.cinemas);

  const mvCinemas = [].concat.apply([], distMV.map(mv=>mv.cinemas));

  // console.log(mvCinemas);

  if (mvCinemas.length > 0) {
    return (
      <div>
        <br />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={6}>
            <MaterialTable
              icons={tableIcons}
              options={{
                selection: true,
                headerStyle: {},
              }}
              columns={[
                { title: "Cinema", field: "name" },
                { title: "Country", field: "CinemaMovies.movieVersionId",lookup:dynamicLookupCountry },
                // { title: "Movie Version", field: "CinemaMovies.movieVersionId",lookup:dynamicLookupMV },
                {
                  title: "Start Date",
                  field: "CinemaMovies.startDate",
                  type: "datetime",
                },
                {
                  title: "End Date",
                  field: "CinemaMovies.endDate",
                  type: "datetime",
                },
                { title: "KDM Generated", field: "CinemaMovies.kdmCreated",lookup:dynamicLookupObject },
                // { title: "KDM Sent To Distributor", field: "CinemaMovies.kdmSentToDistributor",lookup:dynamicLookupObject},
                { title: "KDM Sent To Cinema", field: "CinemaMovies.kdmSentToCinema",lookup:dynamicLookupObject},
                
              ]}
              data={mvCinemas.filter(mvc=>!mvc.CinemaMovies.kdmCreated)}
              title={movie.name + " KDMs"}
              actions={[
                // (rowData) => ({
                //   icon: SettingsIcon,
                //   tooltip: "Generate KDMs",
                //   onClick: (event, rowData) => onClickGenerateKDMs(rowData),
                //   hidden: sessionUser.type.name != "Distributor",
                //   disabled:
                //     !sessionUser.permissions.movies_GenerateKdms
                //      //||rowData.filter(cinema=>(cinema.CinemaMovies.kdmCreated)).length>0,
                // }),

                (rowData) => ({
                  icon: MailIcon,
                  tooltip: "Send KDMs To Distributor",
                  onClick: (event, rowData) => onClickDistributeMovie(rowData,false),
                  hidden: sessionUser.type.name != "Distributor",
                  disabled:
                    !sessionUser.permissions.movies_SendKdmsNotification
                    //  ||
                    // rowData.filter(cinema=>(!cinema.CinemaMovies.kdmCreated)).length>0
                    // ||rowData.filter(cinema=>(cinema.CinemaMovies.kdmSent)).length>0,
                }),

                (rowData) => ({
                  icon: MailOutlineIcon,
                  tooltip: "Send KDMs To Cinema",
                  onClick: (event, rowData) => onClickDistributeMovie(rowData,true),
                  hidden: sessionUser.type.name != "Distributor",
                  disabled:
                    !sessionUser.permissions.movies_SendKdmsNotification
                    //  ||
                    // rowData.filter(cinema=>(!cinema.CinemaMovies.kdmCreated)).length>0
                    // ||rowData.filter(cinema=>(cinema.CinemaMovies.kdmSent)).length>0,
                }),

                (rowData) => ({
                  icon: Delete,
                  tooltip: "Deassign Movie",
                  onClick: (event, rowData) => onClickDeassignCinemaMovies(rowData),
                  // disabled: !sessionUser.permissions.movies_Delete,
                  hidden: sessionUser.type.name != "Distributor",
                })


              ]}
              onSelectionChange={(rows) => setSelectedRowsNum(rows.length)}
            />
          </Grid>
        </Grid>
        <br />
      </div>
    );
  }
  return (
    <div>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <p>No Records to display</p>
        </Grid>
      </Grid>
    </div>
  );
}

const MovieKDMs = connect(mapStateToProps, { distributeMovie,sendKdms,deassignMovie,returnErrors,startLoading,finishLoading })(
  MovieKDMsConnect
);

export default MovieKDMs;
