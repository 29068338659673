import { FormControl, Grid, InputLabel,Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import MaterialUiTextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, Select, TextField } from "formik-material-ui";
import * as _concat from "lodash/concat";
import * as _differenceWith from "lodash/differenceWith";
import React, { useEffect ,useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { addMovieVersion, editMovieVersion } from "../../actions/movieActions";
import { getUsers } from "../../actions/userActions";
import { getCountries } from "../../actions/countryActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({}));

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    countries: state.country.countries,
  };
};

function MovieVersionsDialogConnect({

  movie,
  movieVersion,
  users,
  getUsers,
  getCountries,
  countries,
  sessionUser,
  addMovieVersion,
  editMovieVersion,
  openD,
  handleClose,
}) {
  useEffect(() => {
    getUsers();
    getCountries();

  }, [getUsers, getCountries]);

  const [selectedCountry, setSelectedCountry] = useState(null);


  const isUserCountryEligible = (user)=>{
    if(!selectedCountry){
      return true;
    }else{

      if(user.countries.length==0){
        return true;
      }else{

        const userCountry = user.countries.find(country=>{
          
          // console.log(country.id);
          // console.log(selectedCountry.id);
          return country.id == selectedCountry.id
        
        });

        // console.log(userCountry);
        return userCountry?true:false;
      }
      
    }

  }

  const [distributors, setDistributors] = useState([]);



  useEffect(() => {
    setDistributors(users.filter((user) => (user.type.name == "Distributor" && isUserCountryEligible(user))))
  }, [users]);

  
  useEffect(() => {
    setDistributors(users.filter((user) => (user.type.name == "Distributor" && isUserCountryEligible(user))))
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCountry(movieVersion.country?movieVersion.country:null)
  }, [movieVersion]);



  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    country:Yup.object().required("Required").nullable()
  });

  const closeDialog = () => {
    handleClose();
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />; 
  const handleOnSubmit = (values, { setSubmitting }) => {

    if (movieVersion) {
      const toBeRemovedValues = _differenceWith(
        movieDistributors,
        values.distributors,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      const toBeAddedValues = _differenceWith(
        values.distributors,
        movieDistributors,
        (arrValue, othValue) => arrValue.id == othValue.id
      );

      if (movieProducer.id !== values.producer) {
        toBeAddedValues.push(
          producers.find((prod) => prod.id == values.producer)
        );
        toBeRemovedValues.push(movieVersion.producer);
      }

      const finalValues = {
        ...values,
        ...{ users: toBeAddedValues },
        ...{ removedUsers: toBeRemovedValues },
      };
      delete finalValues.admin;
      delete finalValues.distributors;
      delete finalValues.producer;
      delete finalValues.cinemas;
      delete finalValues.movie;
      finalValues.users = JSON.stringify(finalValues.users);
      finalValues.removedUsers = JSON.stringify(finalValues.removedUsers);
      finalValues.movieId = movie.id;
      // finalValues.movie = JSON.stringify(movie);
      finalValues.country = JSON.stringify(finalValues.country);

      const strmovie = JSON.stringify(movie);

      const final = {...{movie: strmovie},...finalValues}

      // console.log(final);

      editMovieVersion(final);
    } else {
      const finalValues = {
        ...values,
        ...{
          users: _concat(values.distributors, [
            values.admin,
            producers.find((prod) => prod.id == values.producer),
          ]),
        },
      };
      delete finalValues.admin;
      delete finalValues.distributors;
      delete finalValues.producer;
      // console.log(finalValues.users);
      finalValues.users = JSON.stringify(finalValues.users);
      // finalValues.movie = JSON.stringify(movie);
      finalValues.country = JSON.stringify(finalValues.country);

      finalValues.movieId = movie.id;
      // console.log(finalValues);

      const strmovie = JSON.stringify(movie);

      const final = {...{movie: strmovie},...finalValues}

      addMovieVersion(final);
    }

    setSubmitting(false);

    closeDialog();
  };

  const handleFileUploadClick = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if(file.name.split(".").pop()!=="zip"){
      setFieldValue("dkdm", file);
      setFieldValue("dkdmName", file.name);
      setFieldValue("name", file.name);
    }else{
      setFieldValue("supPackage", file);
      setFieldValue("supPackageName", file.name);
    }

  
  };

  const producers = users.filter((user) => user.type.name == "Producer");

  // const distributors = users.filter((user) => user.type.name == "Distributor");

  const movieProducer = movieVersion && movieVersion.users
    ? movieVersion.users.filter((user) => user.type.name == "Producer")[0]
    : undefined;

  const movieAdmin = movieVersion && movieVersion.users
    ? movieVersion.users.filter((user) => user.type.name == "Admin")[0]
    : undefined;

  const movieDistributors = movieVersion && movieVersion.users
    ? movieVersion.users.filter((user) => user.type.name == "Distributor")
    : [];


  const initialValues = movieVersion
    ? {
        ...movieVersion,
        ...{ producer: movieProducer.id },
        ...{ admin: movieAdmin },
        ...{ distributors: movieDistributors },
        ...{oldDkdm:movieVersion.dkdm?movieVersion.dkdm:''},
        ...{ dkdmName: movieVersion.dkdm?movieVersion.dkdm.split("\\").pop():""},
        ...{oldSupPackage:movieVersion.supPackage?movieVersion.supPackage:''},
        ...{ supPackageName: movieVersion.supPackage?movieVersion.supPackage.split("\\").pop():""},
      }
    : {
        name: "",
        producer: producers.length > 0 ? producers[0].id : "",
        admin: sessionUser,
        distributors: [],
        enabled: true,
        dkdm: "",
        dkdmName: "Choose DKDM",
        country:null,
        supPackage:"",
        supPackageName: "Choose Supplemental package",
        isMainVersion:false
      };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openD}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <DialogTitle id="form-dialog-title">Details</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDialog}
              edge="end"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values, setFieldValue ,touched,errors,handleBlur }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Field
                      component={TextField}
                      label="Name"
                      required
                      fullWidth
                      type="text"
                      name="name"
                    />
                    {/* <ErrorMessage name="username" component="div" /> */}
                  </Grid>


                  <Grid item xs={3}>
                  <Autocomplete
                    // multiple
                    // filterSelectedOptions
                    disableCloseOnSelect
                    name={`country`}
                    options={countries}
                    value={values.country}
                    getOptionSelected={(option, value) => {
                      return option.id == value.id;
                    }}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`country`, value);
                      setFieldValue(`distributors`, []);
                      setSelectedCountry(value);                    }}
                      onClose={handleBlur}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                        color="primary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <MaterialUiTextField
                        label={`Restrict Movie Version to Country`}
                        fullWidth
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        name={`country`}
                        {...params}
                      />
                    )}
                  />
                </Grid>

                 

                  <Grid item xs={3}>
                  <Autocomplete
                    multiple
                    // filterSelectedOptions
                    disableCloseOnSelect
                    name={`distributors`}
                    options={distributors}
                    value={values.distributors}
                    getOptionSelected={(option, value) => {
                      return option.id == value.id;
                    }}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`distributors`, value);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                        color="primary"
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <MaterialUiTextField
                        label={`Assign Distributors`}
                        fullWidth
                        name={`distributors`}
                        {...params}
                      />
                    )}
                  />
                </Grid>

                </Grid>

                <br />

                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item>
                    <Field
                      InputProps={{
                        readOnly: true,
                      }}
                      component={TextField}
                      label="DKDM"
                      type="text"
                      name="dkdmName"
                    />
                  </Grid>

                  <Grid item>
                    <input
                      accept=".xml"
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => {
                        handleFileUploadClick(e, setFieldValue);
                      }}
                      hidden
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ marginBottom: 0 }}
                    >
                      {/* <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <CloudUploadIcon />
                      </IconButton> */}
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                <br />

                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item>
                    <Field
                      InputProps={{
                        readOnly: true,
                      }}
                      component={TextField}
                      label="Supplemental Package"
                      type="text"
                      name="supPackageName"
                    />
                  </Grid>

                  <Grid item>
                    <input
                      accept=".zip"
                      id="contained-button-file-zip"
                      type="file"
                      onChange={(e) => {
                        handleFileUploadClick(e, setFieldValue);
                      }}
                      hidden
                    />
                    <label
                      htmlFor="contained-button-file-zip"
                      style={{ marginBottom: 0 }}
                    >
                      {/* <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <CloudUploadIcon />
                      </IconButton> */}
                      <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                <br />
                <Button color="primary" type="submit">
                  Save
                </Button>

                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const MovieVersionsDialog = connect(mapStateToProps, {
  addMovieVersion,
  editMovieVersion,
  getUsers,
  getCountries,
})(MovieVersionsDialogConnect);

export default MovieVersionsDialog;
