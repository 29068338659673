import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { connect } from "react-redux";
import { loadUser } from "../../actions/authActions";
import { editUser } from "../../actions/userActions";
import ProfileDialog from "./ProfileDialog";
import MaterialUiTextField from "@material-ui/core/TextField";
import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as Yup from "yup";
import * as _differenceWith from "lodash/differenceWith";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

function ProfileConnect({ user, editUser, loadUser }) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  const handleOnSubmit = (values, { setSubmitting }) => {
    const finalValues = {
      ...values,
    };

    const toBeRemovedEmails = _differenceWith(
      user.emails,
      values.emails,
      (arrValue, othValue) => arrValue.email == othValue.email
    );

    const toBeAddedEmails = _differenceWith(
      values.emails,
      user.emails,
      (arrValue, othValue) => arrValue.email == othValue.email
    );

    finalValues.emails = toBeAddedEmails;
    finalValues.removedEmails = toBeRemovedEmails;
    delete finalValues.password;

    // console.log(finalValues);

    editUser(finalValues,true);

    setSubmitting(false);
  };

  const email = Yup.object({
    id: Yup.number().nullable(),
    email: Yup.string().email("Invalid email"),
  });

  const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    name: Yup.string().required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    emails: Yup.array().of(email).nullable(),
  });


  const initialValues = user;
  
  return (
    <div>
      <ProfileDialog openD={openDialog} handleClose={handleClose} />

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          touched,
          errors,
          handleBlur,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Field
                  component={TextField}
                  label="Username"
                  required
                  type="text"
                  name="username"
                />
                {/* <ErrorMessage name="username" component="div" /> */}
              </Grid>

              <Grid item xs={2}>
                <Field
                  component={TextField}
                  required
                  label="Main Email"
                  type="email"
                  name="email"
                />
                {/* <ErrorMessage name="email" component="div" /> */}
              </Grid>

              <Grid item xs={2}>
                <Field
                  component={TextField}
                  label="Name"
                  type="text"
                  name="name"
                  required
                />
                {/* <ErrorMessage name="name" component="div" /> */}
              </Grid>

              <Grid item xs={2}>
                <Field
                  component={TextField}
                  label="Phone"
                  type="tel"
                  name="phone"
                />
                {/* <ErrorMessage name="phone" component="div" /> */}
              </Grid>

              <Grid item xs={3}></Grid>

              <Grid
                item
                container
                xs={12}
                direction="row"
                alignItems="flex-end"
              ></Grid>
            </Grid>

            <hr hidden={user.type.name != "Distributor"} />

            <br hidden={user.type.name != "Distributor"}/>
            {/* <h5>Additional Emails</h5> */}

            <Grid item xs={12} hidden={user.type.name != "Distributor"}>
              <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                  <div>
                    {/* <p>Additional Emails</p> */}

                    <Grid container>
                      <Grid item xs={3}>
                        <Typography variant="h6" noWrap>
                          Additional Emails
                          <IconButton
                            edge="end"
                            aria-label="add"
                            onClick={() => arrayHelpers.push({ email: "" })}
                            // disabled={values.emails.length >= 5}
                          >
                            <AddIcon />
                          </IconButton>
                        </Typography>
                      </Grid>

                      {/* <Grid item xs={3}>
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => arrayHelpers.push({ email: "" })}
                          disabled={values.emails.length >= 5}
                        >
                          
                          <AddIcon />
                        </IconButton>
                        </Grid> */}
                    </Grid>

                    {values.emails.map((email, index) => (
                      <div key={index}>
                        {/** both these conventions do the same */}

                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Field
                              name={`emails[${index}].email`}
                              component={TextField}
                              label="Email"
                              type="email"
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              color="secondary"
                              edge="end"
                              aria-label="delete"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                )}
              />
            </Grid>

            <br />

            <Button type="submit" color="primary" disabled={isSubmitting}>
              Submit
            </Button>

            <Button onClick={handleClickOpen} color="primary">
              Change Password
            </Button>
          </Form>
        )}
      </Formik>

    </div>
  );
}

const Profile = connect(mapStateToProps, { editUser, loadUser })(
  ProfileConnect
);

export default Profile;
